import { Divider, Grid, TextField, Typography } from '@mui/material';
import BoxImagesWithPreview from 'components/BoxImagesWithPreview';
import BoxImagesWithoutPreview from 'components/BoxImagesWithoutPreview';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
	destination: TravelDestination | null;
	updateDestinationKey: (mainKey: keyof TravelDestination, subKey?: keyof ItemLocation | keyof Contact) => any;
	selectedImages: File[];
	showImages: string[] | null;
	handleImageRemove: (index: number) => void;
	setSelectedImages: Dispatch<SetStateAction<File[]>>;
	type: 'create' | 'edit';
};
const FilesDestination = ({
	destination,
	updateDestinationKey,
	selectedImages,
	handleImageRemove,
	setSelectedImages,
	showImages,
	type,
}: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="body1">{t(`common:file_plural`)}</Typography>
				<Divider sx={{ width: '100%', my: 1 }} />
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="url_video"
					label={t('common:url_video')}
					value={destination?.video}
					placeholder='<iframe width="560" height="315" src="https://www.youtube.com/embed/v6S9fYYToYI?si=fzTrx26ZNxljq7Zv"...></iframe>'
					fullWidth={true}
					variant="outlined"
					onChange={updateDestinationKey('video')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
			{type === 'edit' && showImages && showImages.length > 0 && (
				<BoxImagesWithPreview images={showImages} handleImageRemove={handleImageRemove} destination={destination} />
			)}

			<BoxImagesWithoutPreview
				images={selectedImages}
				handleImageRemove={handleImageRemove}
				setSelectedImages={setSelectedImages}
			/>
		</>
	);
};

export default FilesDestination;
