import { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStringFromData } from 'lib/helpers';

import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import { reducer } from 'lib/queryReducer';

import FullLayout from 'components/layouts/FullLayout/FullLayout';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import SearchToolbar from 'components/SearchToolbar/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable/ResponsiveTable';
import MobileItem from './MobileItem';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Box from '@mui/material/Box/Box';
import { rowsPerPageOptions } from 'config/constants';
import usePaginatedData from 'hooks/usePaginatedData';
import { fetchAccommodation } from 'lib/models/accommodations';
import AccommodationForm from 'components/AccommodationForm';
import { Tooltip } from '@mui/material';

type Props = {
	reload?: boolean;
	setReload?: Dispatch<SetStateAction<boolean>>;
};

function AccommodationsTable({ reload, setReload }: Props) {
	const { t } = useTranslation();
	const [accommodationEdit, setAccommodationEdit] = useState<Accommodation | null>(null);
	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: accommodations,
		total,
		requestTotal,
		refetch,
	} = usePaginatedData(fetchAccommodation, query, '_id', resetMobileState);

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch]
	);

	useEffect(() => {
		if (reload && setReload) {
			refetch();
			setReload(false);
		}
	}, [refetch, reload, setReload]);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={accommodations}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (accommodation) => (
						<MobileItem accommodation={accommodation} setAccommodationEdit={setAccommodationEdit} />
					),
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:description')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:including')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:not_including')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:price')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:person_plural')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (accommodation) => (
						<>
							<Tooltip title={t(`common:edit`)} arrow placement="top-start">
								<TableRow
									tabIndex={-1}
									key={accommodation._id}
									sx={{
										'&:hover': {
											backgroundColor: 'lightgray',
											cursor: 'pointer',
										},
									}}
									onClick={() => setAccommodationEdit(accommodation)}
								>
									<TableCell>{accommodation.name}</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accommodation.description && accommodation.description.length > 45
												? `${accommodation.description.slice(0, 47)}...`
												: accommodation.description}
										</Box>
									</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accommodation.including?.map((item, index, array) => (
												<Fragment key={index}>
													{item}
													{index < array.length - 1 ? ', ' : '.'}
												</Fragment>
											))}
										</Box>
									</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accommodation.not_including?.map((item, index, array) => (
												<Fragment key={index}>
													{item}
													{index < array.length - 1 ? ', ' : '.'}
												</Fragment>
											))}
										</Box>
									</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accommodation.price?.currency} {accommodation.price?.value}
										</Box>
									</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{accommodation.price?.persons}
										</Box>
									</TableCell>
								</TableRow>
							</Tooltip>
						</>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>

			<AccommodationForm
				setReload={setReload}
				reload={reload}
				accommodation={accommodationEdit}
				setAccommodation={setAccommodationEdit}
				type="edit"
			/>
		</FullLayout>
	);
}

export default AccommodationsTable;
