export const getFileDisplay = (file: File | null, nameButton: string) => {
	if (file) {
		const fileName = file.name;
		const extension = fileName.split('.').pop();
		if (!extension) return fileName.substring(0, 20) + '...';
		const fileNameWithoutExtension = fileName.substring(0, fileName.length - extension.length - 1);
		const truncatedFileName =
			fileNameWithoutExtension.length > 17
				? fileNameWithoutExtension.substring(0, 17) + '...'
				: fileNameWithoutExtension;

		return `${truncatedFileName}.${extension}`;
	}

	return nameButton;
};
