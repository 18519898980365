import { Autocomplete, Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import AddItem from 'components/AddItem/AddItem';
import InputListManager from 'components/InputListManager/InputListManager';
import { useErrorParser } from 'hooks/useErrorParser';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { cleanArray } from 'lib/cleanArray';
import {
	createActivity,
	createActivityWithFiles,
	updatedActivity,
	updatedActivityWithFiles,
} from 'lib/models/activities';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbarError } from 'lib/helpers';
import BoxImagesWithPreview from 'components/BoxImagesWithPreview';
import BoxImagesWithoutPreview from 'components/BoxImagesWithoutPreview';
import { fetchFiles } from 'lib/models/generics';

type ActivityObject<T> = T & { activity: Activity };
type Props<T> = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	activity: Activity | null;
	setActivity: Dispatch<SetStateAction<Activity | null>>;
	setAssignActivity?:
		| Dispatch<SetStateAction<ActivityObject<T> | null>>
		| Dispatch<SetStateAction<TravelDestination | null>>;
	type: 'create' | 'edit';
};

const currencies: Currency[] = ['USD', 'ARS', 'BRL'];

function ActivityForm<T>({ setReload, reload, activity, setActivity, setAssignActivity, type }: Props<T>) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const [showImages, setShowImages] = useState<string[] | null>(null);
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);
	const createOrEdit = t(type === 'create' ? `common:create` : `common:edit`);

	function updateActivityKey(mainKey: keyof Activity, subKey?: keyof Price) {
		if (subKey === undefined) {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setActivity({ ...(activity || {}), [mainKey]: ev.target.value } as Activity);
			};
		} else {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setActivity((prevActivity) => {
					if (!prevActivity) {
						return null;
					}
					// If subKey is defined, it updates a nested property
					return Object.assign({}, prevActivity, {
						[mainKey]: Object.assign({}, prevActivity[mainKey], {
							[subKey]: ev.target.value,
						}),
					}) as Activity;
				});
			};
		}
	}

	async function updated(activity: Activity) {
		if (!activity._id) {
			return;
		}
		if (!selectedImages || selectedImages.length < 1) {
			return await updatedActivity(activity, activity._id);
		} else {
			return await updatedActivityWithFiles(activity._id, activity, selectedImages);
		}
	}

	async function add(activity: Activity): Promise<Activity> {
		if (!selectedImages || selectedImages.length < 1) {
			return await createActivity(activity);
		} else {
			return await createActivityWithFiles(activity, selectedImages);
		}
	}

	async function AddActivity(activity: Activity | null) {
		if (!activity) {
			return;
		}

		if (activity.including && activity.including.length > 1) {
			activity.including = cleanArray(activity.including);
		}

		if (activity.not_including && activity.not_including.length > 1) {
			activity.not_including = cleanArray(activity.not_including);
		}

		try {
			//await createActivity(activity);
			if (!activity._id) {
				const resultActivity = await add(activity);
				setAssignActivity &&
					setAssignActivity((prevDestination: ActivityObject<any> | null) => {
						if (prevDestination) {
							return {
								...prevDestination,
								activity: [...(prevDestination.activity || []), resultActivity],
							};
						}
						return {
							activity: [resultActivity],
						};
					});
			} else {
				await updated(activity);
			}
			setActivity(null);
			if (setReload) {
				setReload(true);
			}
			successSnackbar();
			setSelectedImages([]);
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	const loadImages = async (files: string[] | null) => {
		try {
			if (files === null) {
				return;
			}
			const fileResponse = await fetchFiles(files);
			if (fileResponse) {
				const images = fileResponse.map((fileData) => `data:image/${fileData.type};base64,${fileData.file}`);
				setShowImages(images);
			}
		} catch (error) {
			console.warn(error);
		}
	};

	useEffect(() => {
		if (
			activity?.images &&
			(activity?.images.length > 1 || (activity?.images[0] !== '' && activity?.images.length > 0))
		) {
			loadImages(activity.images);
		}
	}, [activity?.images]);

	const handleImageRemove = (index: number, edit?: string) => {
		if (edit && activity?.images) {
			const updatedImages = [...(activity?.images || [])];
			const updatedViewImages = [...(showImages || [])];
			updatedImages.splice(index, 1);
			updatedViewImages.splice(index, 1);
			setActivity((prevActivity: Activity | null) => {
				if (!prevActivity) {
					return null;
				}
				return {
					...prevActivity,
					images: updatedImages,
				};
			});
			setShowImages(updatedViewImages);
		} else {
			const updatedImages = [...selectedImages];
			updatedImages.splice(index, 1);
			setSelectedImages(updatedImages);
		}
	};

	return (
		<AddItem
			title={`${createOrEdit} ${t('common:activity').toLowerCase()}`}
			open={!!activity}
			onClose={() => setActivity(null)}
		>
			<TextField
				id="name"
				label={t('common:name')}
				value={activity?.name}
				fullWidth={true}
				variant="outlined"
				onChange={updateActivityKey('name')}
				sx={{ mb: 3 }}
				error={activity?.name === ''}
				helperText={activity?.name === '' ? t('system:emptyField') : ''}
			/>
			<TextField
				id={`description`}
				label={t(`common:description`)}
				multiline
				fullWidth
				rows={5}
				value={activity?.description}
				onChange={updateActivityKey('description')}
				sx={{ backgroundColor: '#fff' }}
			/>
			<InputListManager<Activity>
				item={activity}
				setItem={setActivity}
				label={t('common:including')}
				identifier="including"
			/>
			<InputListManager<Activity>
				item={activity}
				setItem={setActivity}
				label={t('common:not_including')}
				identifier="not_including"
			/>
			<Box
				sx={{
					borderWidth: 2,
					borderRadius: 4,
					minHeight: 50,
					maxHeight: 400,
					width: '100%',
					mb: 2,
					py: 2,
					px: 2,
					backgroundColor: '#f0f0f0',
					borderColor: '#d3d3d3',
					borderStyle: 'solid',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<Autocomplete
						disablePortal
						fullWidth
						value={activity?.price?.currency}
						onChange={(event, newValue) => {
							const syntheticEvent = {
								target: { value: newValue },
							} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
							updateActivityKey('price', 'currency')(syntheticEvent);
						}}
						options={currencies}
						sx={{ mb: 1, backgroundColor: 'white' }}
						renderInput={(params) => <TextField {...params} label={t('common:currency')} />}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<TextField
						label={t('common:price')}
						value={activity?.price?.value}
						fullWidth={true}
						variant="outlined"
						type="number"
						onChange={updateActivityKey('price', 'value')}
						InputProps={{ inputProps: { min: 1 } }}
						sx={{ mb: 1, backgroundColor: 'white' }}
					/>
				</Box>

				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<TextField
						label={t('common:persons')}
						value={activity?.price?.persons}
						fullWidth={true}
						variant="outlined"
						type="number"
						onChange={updateActivityKey('price', 'persons')}
						InputProps={{ inputProps: { min: 1 } }}
						sx={{ mb: 1, backgroundColor: 'white' }}
					/>
				</Box>
			</Box>
			<Grid item xs={12}>
				<Typography variant="body1">{t(`common:file_plural`)}</Typography>
				<Divider sx={{ width: '100%', my: 1 }} />
			</Grid>
			<Grid item xs={12}>
				<TextField
					id="url_video"
					label={t('common:url_video')}
					value={activity?.video}
					fullWidth={true}
					variant="outlined"
					onChange={updateActivityKey('video')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
			{type === 'edit' && showImages && showImages.length > 0 && (
				<BoxImagesWithPreview images={showImages} handleImageRemove={handleImageRemove} destination={activity} />
			)}

			<BoxImagesWithoutPreview
				images={selectedImages}
				handleImageRemove={handleImageRemove}
				setSelectedImages={setSelectedImages}
			/>
			<Divider sx={{ width: '100%', my: 1, mt: 2 }} />
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
				<Button onClick={() => AddActivity(activity)} variant="outlined" disabled={activity?.name === '' || reload}>
					{saveOrEdit}
				</Button>
			</Box>
		</AddItem>
	);
}

export default ActivityForm;
