/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { updatedContact } from 'lib/models/contacts';
import moment from 'moment';
import { Link } from 'react-router-dom';

//const OPTIONS: Status[] = ['rejected', 'pending', 'approved'];

type Props = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	reservation: Reservation | null;
	setReservation: Dispatch<SetStateAction<Reservation | null>>;
};
/*
const useStyles = makeStyles((theme: any) => ({
	container: {
		padding: theme.spacing(2),
		backgroundColor: '#f5f5f5',
		fontFamily: 'Arial, sans-serif',
		maxWidth: '600px',
		margin: 'auto',
	},
}));
*/
function ReservationForm({ setReload, reload, reservation, setReservation }: Props) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const saveOrEdit = t(`common:edit`);

	function updateReservationKey(mainKey: keyof Reservation) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setReservation((prevReservation) => {
				if (!prevReservation) {
					return null;
				}

				return { ...prevReservation, [mainKey]: ev.target.value } as Reservation;
			});
		};
	}

	async function uploadReservation(reservation: Reservation | null, muteNotification?: boolean) {
		if (!reservation || !reservation._id) {
			return;
		}

		try {
			await updatedContact(reservation, reservation._id);
			if (setReload) {
				setReload(true);
			}
			if (!muteNotification) {
				successSnackbar();
			}
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	useEffect(() => {
		if (reservation?.read !== undefined && reservation.read !== true && reservation._id) {
			uploadReservation({ ...reservation, read: true }, true);
		}
	}, []);

	return (
		<Box
			sx={{
				flexGrow: 1,
				padding: 3,
				overflowY: 'auto',
			}}
		>
			<Typography variant="h4" gutterBottom>
				{t(`common:reservationDate`)}:
			</Typography>
			<Typography variant="body1">
				{t(`common:name`)}: {reservation?.name}
			</Typography>
			<Typography variant="body1">
				{t(`common:email`)}: {reservation?.email}
			</Typography>
			<Typography variant="body1">
				{t(`common:phone`)}: {reservation?.phone}
			</Typography>
			<Typography variant="body1">
				{t(`common:reservationDate`)}: {reservation ? moment(reservation.date).format('DD/MM/YYYY') : ''}
			</Typography>
			<Typography variant="body1">
				{t(`common:message`)}: {reservation?.message}
			</Typography>
			<Typography variant="body1">
				{t(`common:locationId`)}: <Link to={`/locations/${reservation?.itemId}`}>{reservation?.itemId}</Link>
			</Typography>
			<Typography variant="body1">
				{t(`common:reserveStatus`)}:{' '}
				<RadioGroup value={reservation?.status} onChange={updateReservationKey('status')}>
					<FormControlLabel value="rejected" control={<Radio />} label={t(`common:rejected`)} />
					<FormControlLabel value="pending" control={<Radio />} label={t(`common:pendingStatus`)} />
					<FormControlLabel value="approved" control={<Radio />} label={t(`common:approved`)} />
				</RadioGroup>
			</Typography>

			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
				<Button
					onClick={() => uploadReservation(reservation)}
					variant="outlined"
					disabled={reservation?.name === '' || reload}
				>
					{saveOrEdit}
				</Button>
			</Box>
		</Box>
	);
}

export default ReservationForm;
