import { Paper, styled } from '@mui/material';

export const initialAvailability: OperatingHours = {
	monday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	tuesday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	wednesday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	thursday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	friday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	saturday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
	sunday: {
		startTime: { hours: '12', minutes: '00', period: 'AM' },
		endTime: { hours: '12', minutes: '00', period: 'AM' },
		open: false,
	},
};

export const itemSortBy = ['edition', 'price'] as const;

export const sortOrder = ['desc', 'asc'] as const;

export const initialStateDestination = {
	name: '',
	description: '',
	province: '',
	address: '',
	stateLocation: {
		open: false,
		date: '',
	},
	location: {} as ItemLocation,
	meeting_point: '',
	accommodation: {
		name: '',
		_id: '',
		including: [''],
		not_including: [''],
	} as Accommodation,
	//activity: { name: '', _id: '', including: [''], not_including: [''] } as Activity,
	activity: [] as Activity[],
	operatingHours: initialAvailability,
	images: [''],
	video: '',
	tag: {
		name: '',
		_id: '',
	} as Tag,
	contact: {} as Contact,
	maps: '',
	instagram: '',
};

export const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));
