import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import AddItem from 'components/AddItem/AddItem';
import {
	Autocomplete,
	Box,
	Button,
	Card,
	CardMedia,
	Checkbox,
	Chip,
	Grid,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { createTag, createTagWithFile, fetchFile, updatedTag, updatedTagWithFile } from 'lib/models/tags';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { getFileDisplay } from 'lib/showFileName';
import { provincesArgentina } from 'config/constants';
import VillaIcon from '@mui/icons-material/Villa';
//import PreviewImage from 'components/PreviewImage';

type TaggedObject<T> = T & { tag: Tag };
type Props<T> = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	tag: Tag | null;
	setTag: Dispatch<SetStateAction<Tag | null>>;
	setAssignTag?: Dispatch<SetStateAction<TaggedObject<T> | null>> | Dispatch<SetStateAction<TravelDestination | null>>;
	//setAssignTag?: (item?: Tag) => void;
	type: 'create' | 'edit';
};

function TagForm<T>({ setReload, reload, tag, setTag, setAssignTag, type }: Props<T>) {
	const { t } = useTranslation();
	const [file, setFile] = useState<File | null>(null);
	const [showImage, setShowImage] = useState<string | null>();
	const [imageExtension, setImageExtension] = useState<string | undefined>('unknown');
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const [, setOpenModal] = useState(false);
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);
	const createOrEdit = t(type === 'create' ? `common:create` : `common:edit`);

	function updateTagKey(mainKey: keyof Tag) {
		/*return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setTag({ ...(tag || {}), [mainKey]: ev.target.value } as Tag);
		};*/
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setTag((prevTag) => {
				if (!prevTag) {
					return null;
				}

				if (mainKey === 'province') {
					const updatedProvince = [...(prevTag.province || []), ev.target.value];
					return { ...prevTag, [mainKey]: updatedProvince } as Tag;
				}

				return { ...prevTag, [mainKey]: ev.target.value } as Tag;
			});
		};
	}

	const handleDeleteProvince = (indexToDelete: number) => {
		setTag((prevTag) => {
			if (!prevTag || !prevTag?.province) {
				return prevTag;
			}

			const updatedProvince = [...prevTag.province];
			updatedProvince.splice(indexToDelete, 1);

			return {
				...prevTag,
				province: updatedProvince,
			};
		});
	};

	const loadImage = async (file: string | null) => {
		try {
			if (file === null) {
				return;
			}
			const fileResponse = await fetchFile(file);
			setShowImage(fileResponse?.file);
			setImageExtension(fileResponse?.type);
		} catch (error) {
			console.warn(error);
		}
	};

	async function updated(tag: Tag) {
		if (!tag._id) {
			return;
		}
		if (file !== null) await updatedTagWithFile(tag._id, tag, file);
		else await updatedTag(tag, tag._id);
	}

	async function add(tag: Tag): Promise<Tag> {
		if (file !== null) return await createTagWithFile(tag, file);
		else return await createTag(tag);
	}

	async function uploadTag(tag: Tag | null) {
		if (!tag) {
			return;
		}

		try {
			if (!tag._id) {
				const resultTag = await add(tag);
				setAssignTag && //setAssignTag(resultTag);
					setAssignTag((prevDestination: TaggedObject<any> | null) => {
						if (prevDestination) {
							return {
								...prevDestination,
								tag: resultTag,
							};
						}
						return {
							tag: resultTag,
						};
					});
			} else {
				await updated(tag);
			}

			setTag(null);
			setFile(null);
			if (setReload) {
				setReload(true);
			}
			successSnackbar();
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	useEffect(() => {
		if (tag?.image && tag.image !== '') {
			loadImage(tag.image);
		}
	}, [tag?.image]);

	return (
		<AddItem
			title={`${createOrEdit} ${t('common:tag').toLowerCase()}`}
			open={!!tag}
			onClose={() => {
				setFile(null);
				setShowImage(null);
				setTag(null);
			}}
		>
			<TextField
				id="name"
				label={t('common:name')}
				value={tag?.name}
				fullWidth={true}
				variant="outlined"
				onChange={updateTagKey('name')}
				sx={{ mb: 3 }}
				error={tag?.name === ''}
				helperText={tag?.name === '' ? t('system:emptyField') : ''}
			/>
			{/* Autocomplete for Province */}
			{/* Replace options with your actual data */}

			<Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
				<Autocomplete
					disablePortal
					fullWidth
					onChange={(event, newValue) => {
						if (newValue && !tag?.province?.includes(newValue as ProvincesArgentina)) {
							const selectedTag = (provincesArgentina ?? []).find((province) => province === newValue);
							const syntheticEvent = {
								target: { value: selectedTag } as unknown as HTMLInputElement | HTMLTextAreaElement,
							} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
							updateTagKey('province')(syntheticEvent);
						}
					}}
					options={provincesArgentina}
					getOptionLabel={(provinceName) => {
						const province = provincesArgentina.find((a) => a === provinceName);
						return province ? province : '';
					}}
					sx={{ mb: 1, backgroundColor: 'white' }}
					renderInput={(params) => <TextField {...params} fullWidth label={t('common:province')} />}
				/>
			</Grid>
			<Grid item xs={12}>
				<Box
					sx={{
						borderWidth: 1,
						borderRadius: 3,
						minHeight: 50,
						maxHeight: 400,
						margin: 'auto',
						mb: 2,
						py: 2,
						px: 2,
						backgroundColor: '#f9f9f9',
						borderColor: '#d3d3d3',
						borderStyle: 'solid',
						overflowY: 'auto',
						maxWidth: '100%',
						overflowX: 'auto',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Stack direction="row" sx={{ flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
						{tag?.province &&
							(tag?.province?.length ?? 0) > 0 &&
							(tag?.province ?? []).map(
								(province, index) =>
									province && (
										<Chip
											key={index}
											icon={<VillaIcon />}
											onDelete={() => handleDeleteProvince(index)}
											label={province}
											sx={{ mr: 2, mb: 1 }}
										/>
									)
							)}
					</Stack>
				</Box>
			</Grid>

			<TextField
				id={`description`}
				label={t(`common:description`)}
				multiline
				fullWidth
				rows={5}
				value={tag?.description}
				onChange={updateTagKey('description')}
				sx={{ backgroundColor: '#fff' }}
			/>
			<Box sx={{ mb: 3, mt: 3, display: 'flex', alignItems: 'center' }}>
				<Typography variant="body1">{t(`common:show_in_screen`).toUpperCase()}</Typography>
				<Checkbox
					checked={tag?.show}
					onChange={(e) =>
						updateTagKey('show')({ target: { value: e.target.checked } } as unknown as ChangeEvent<HTMLInputElement>)
					}
				/>
			</Box>
			{showImage && showImage !== '' && file === null && (
				<>
					<Box sx={{ mb: 3, mt: 3 }}>
						<Card sx={{ maxWidth: '50%' }}>
							<CardMedia
								component="img"
								alt="Image Preview"
								image={`data:image/${imageExtension};base64,${showImage}`}
								sx={{
									objectFit: 'cover',
									width: '100%',
									height: '100%',
								}}
								onClick={() => setOpenModal(true)}
							/>
						</Card>
					</Box>
					{/*<PreviewImage
						open={openModal}
						close={() => {
							setOpenModal(false);
						}}
						showImage={`data:image/${imageExtension};base64,${showImage}`}
					/>*/}
				</>
			)}

			<Box sx={{ mb: 3, mt: 3 }}>
				<input
					type="file"
					style={{ display: 'none' }}
					id="upload-file"
					onChange={(e) => e.target.files && setFile(e.target.files[0])}
					accept="image/*"
				/>
				<label htmlFor="upload-file">
					<Button variant="contained" component="span">
						{showImage !== null && type === 'edit'
							? getFileDisplay(file, t('common:replace_image'))
							: getFileDisplay(file, t('common:upload_image'))}
					</Button>
				</label>
			</Box>

			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
				<Button onClick={() => uploadTag(tag)} variant="outlined" disabled={tag?.name === '' || reload}>
					{saveOrEdit}
				</Button>
			</Box>
		</AddItem>
	);
}

export default TagForm;
