import { Divider, Grid, TextField, Typography } from '@mui/material';
import { validateEmail } from 'lib/validateEmail';
import { useTranslation } from 'react-i18next';

type Props = {
	destination: TravelDestination | null;
	updateDestinationKey: (mainKey: keyof TravelDestination, subKey?: keyof ItemLocation | keyof Contact) => any;
};

const ContactDestination = ({ destination, updateDestinationKey }: Props) => {
	const { t } = useTranslation();

	return (
		<>
			<Typography variant="body1">{t(`common:contact`)}</Typography>
			<Divider sx={{ width: '100%', my: 1 }} />
			<Grid item xs={4}>
				<TextField
					label={t('common:name')}
					value={destination?.contact?.name}
					fullWidth={true}
					variant="outlined"
					onChange={updateDestinationKey('contact', 'name')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					label={t('common:email')}
					fullWidth={true}
					variant="outlined"
					type="email"
					error={
						!destination?.contact?.email ||
						destination?.contact?.email === '' ||
						!validateEmail(destination?.contact?.email)
					}
					helperText={
						!destination?.contact?.email ||
						destination?.contact?.email === '' ||
						!validateEmail(destination?.contact?.email)
							? `${t('system:emptyField')} ${t('common:or')} ${t('system:errorEmail')}`
							: ''
					}
					value={destination?.contact?.email}
					onChange={updateDestinationKey('contact', 'email')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>

			<Grid item xs={4}>
				<TextField
					label={t('common:phone')}
					value={destination?.contact?.phone}
					fullWidth={true}
					variant="outlined"
					onChange={updateDestinationKey('contact', 'phone')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					label={t('common:WhatsApp')}
					value={destination?.contact?.whatsapp}
					fullWidth={true}
					variant="outlined"
					onChange={updateDestinationKey('contact', 'whatsapp')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextField
					label={t('common:mobilePhone')}
					value={destination?.contact?.mobilePhone}
					fullWidth={true}
					variant="outlined"
					onChange={updateDestinationKey('contact', 'mobilePhone')}
					sx={{ mb: 3, backgroundColor: 'white' }}
				/>
			</Grid>
		</>
	);
};

export default ContactDestination;
