import { API_DOMAIN, ACCOMMODATIONS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchAccommodationSearchProps = {
	search?: string | null;
};

export type FetchAccommodationsProps = FetchAccommodationSearchProps & PaginationProps;

export async function fetchAccommodation(props: FetchAccommodationsProps): Promise<PaginateSource<Accommodation>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'name' || 'description' || 'including' || 'not_including' || 'price.currency']:
				props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Accommodation>>(`${API_DOMAIN}${ACCOMMODATIONS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function createAccommodation(accommodation: Accommodation): Promise<Accommodation> {
	const url = `${API_DOMAIN}${ACCOMMODATIONS_URL}`;
	const response = await api.post<Accommodation>(url, { accommodation });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || accommodation;
}

export async function updatedAccommodation(accommodation: Accommodation, _id: string) {
	const url = `${API_DOMAIN}${ACCOMMODATIONS_URL}/${_id}`;
	const response = await api.patch(url, { accommodation });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}
