import { Box, Card, CardMedia, Grid, IconButton, Stack } from '@mui/material';
import { Item } from 'types/constants';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PreviewImage from 'components/PreviewImage';
type Props = {
	destination?: TravelDestination | Activity | null;
	images: string[];
	handleImageRemove: (index: number, edit?: string) => void;
};

const BoxImagesWithPreview = ({ images, handleImageRemove }: Props) => {
	const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const handleImageClick = (index: number) => {
		setSelectedImageIndex(index);
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setSelectedImageIndex(null);
	};

	return (
		<>
			<Grid item xs={12}>
				<Box
					sx={{
						borderWidth: 1,
						borderRadius: 3,
						minHeight: 50,
						maxHeight: 400,
						margin: 'auto',
						mb: 2,
						py: 2,
						px: 2,
						backgroundColor: '#f9f9f9',
						borderColor: '#d3d3d3',
						borderStyle: 'solid',
						overflowY: 'auto',
						maxWidth: '100%',
						overflowX: 'auto',
					}}
				>
					<Stack direction="row" sx={{ flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
						{images.map((image, index) => (
							<Item key={index} sx={{ mb: 2, mr: 2, width: 100, height: 100, position: 'relative' }}>
								<Card sx={{ maxWidth: '100%', height: '100%' }}>
									<CardMedia
										component="img"
										alt="Image Preview"
										height="100%"
										image={image}
										sx={{
											objectFit: 'cover',
											width: '100%',
											height: '100%',
										}}
										onClick={() => handleImageClick(index)}
									/>
								</Card>
								<IconButton
									sx={{
										background: 'rgba(255, 255, 255, 0.8)',
										fontSize: '14px',
										width: '24px',
										height: '24px',
										position: 'absolute',
										top: '4px',
										right: '4px',
									}}
									onClick={() => handleImageRemove(index, 'edit')}
								>
									<CloseIcon sx={{ fontSize: '16px' }} />
								</IconButton>
							</Item>
						))}
					</Stack>
				</Box>
			</Grid>
			{selectedImageIndex !== null && images && (
				<PreviewImage open={openModal} close={handleCloseModal} showImage={images[selectedImageIndex]} />
			)}
		</>
	);
};

export default BoxImagesWithPreview;
