import { useTranslation } from 'react-i18next';
import ScreenContainer from 'components/containers/ScreenContainer';

import { Box } from '@mui/material';
import ReservationsTable from 'components/ReservationsTable';

function AboutUs() {
	const { t } = useTranslation();

	return (
		<ScreenContainer title={t('common:reservations')} goBack={true}>
			<Box sx={{ mb: 2 }}>
				<ReservationsTable />
			</Box>
		</ScreenContainer>
	);
}

export default AboutUs;
