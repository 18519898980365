import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Button, Card, CardMedia, Grid, TextField, Typography } from '@mui/material';
import { fetchFile } from 'lib/models/tags';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { getFileDisplay } from 'lib/showFileName';
import { createOrUpdatedCustomizedHome, createOrUpdatedCustomizedHomeWithFiles } from 'lib/models/customizedHome';
import { initialStateCustomizedHome } from 'config/constants';
//import PreviewImage from 'components/PreviewImage';

type Props<T> = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	customizedHome: CustomizedHome | null;
	setCustomizedHome: Dispatch<SetStateAction<CustomizedHome | null>>;
	type: 'create' | 'edit';
};

function CustomizedHomeForm<T>({ setReload, reload, customizedHome, setCustomizedHome, type }: Props<T>) {
	const { t } = useTranslation();
	const [file, setFile] = useState<File | null>(null);
	const [showImage, setShowImage] = useState<string | null>();
	const [imageExtension, setImageExtension] = useState<string | undefined>('unknown');
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const [, setOpenModal] = useState(false);
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);

	function updateCustomizedHomeKey(mainKey: keyof CustomizedHome) {
		/*return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setCustomizedHome({ ...(customizedHome || {}), [mainKey]: ev.target.value } as CustomizedHome);
		};*/
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setCustomizedHome((prevCustomizedHome) => {
				if (!prevCustomizedHome) {
					return initialStateCustomizedHome;
				}

				return { ...prevCustomizedHome, [mainKey]: ev.target.value } as CustomizedHome;
			});
		};
	}

	const loadImage = async (file: string | null) => {
		try {
			if (file === null) {
				return;
			}
			const fileResponse = await fetchFile(file);
			setShowImage(fileResponse?.file);
			setImageExtension(fileResponse?.type);
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	};

	async function uploadConfig(config: CustomizedHome | null) {
		if (!config) {
			return;
		}

		try {
			if (setReload) {
				setReload(true);
			}
			await addOrUpdateConfig(config);
			successSnackbar();
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
		setReload && setReload(false);
	}

	async function addOrUpdateConfig(config: CustomizedHome): Promise<CustomizedHome> {
		if (file === null) {
			return await createOrUpdatedCustomizedHome(config);
		} else {
			const result = await createOrUpdatedCustomizedHomeWithFiles(config, file);

			return result;
		}
		//return await createOrUpdatedConfig(config);
	}

	useEffect(() => {
		if (customizedHome?.image && customizedHome.image !== '') {
			loadImage(customizedHome.image);
		}
	}, [customizedHome?.image]);

	return (
		<Box
			sx={{
				flexGrow: 1,
				padding: 3,
				overflowY: 'auto',
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<Typography variant="h6" component="h2" className="text-center">
						{t('common:quotation_section')}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id="name"
						label={t('common:title')}
						value={customizedHome?.title}
						fullWidth={true}
						variant="outlined"
						onChange={updateCustomizedHomeKey('title')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>

				<Grid item xs={12} sm={12}>
					<TextField
						id={`text`}
						label={t(`common:text`)}
						multiline
						fullWidth
						rows={5}
						value={customizedHome?.text}
						onChange={updateCustomizedHomeKey('text')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>

				{showImage && showImage !== '' && file === null && (
					<Grid item xs={12}>
						<Box sx={{ mb: 3, mt: 3 }}>
							<Card sx={{ maxWidth: '50%' }}>
								<CardMedia
									component="img"
									alt="Image Preview"
									image={`data:image/${imageExtension};base64,${showImage}`}
									sx={{
										objectFit: 'cover',
										width: '100%',
										height: '100%',
									}}
									onClick={() => setOpenModal(true)}
								/>
							</Card>
						</Box>
						{/*<PreviewImage
						open={openModal}
						close={() => {
							setOpenModal(false);
						}}
						showImage={`data:image/${imageExtension};base64,${showImage}`}
					/>*/}
					</Grid>
				)}
				<Grid item xs={12}>
					<Box sx={{ mb: 3, mt: 3 }}>
						<input
							type="file"
							style={{ display: 'none' }}
							id="upload-file"
							onChange={(e) => e.target.files && setFile(e.target.files[0])}
							accept="image/*"
						/>
						<label htmlFor="upload-file">
							<Button variant="contained" component="span">
								{showImage !== null && type === 'edit'
									? getFileDisplay(file, t('common:replace_image'))
									: getFileDisplay(file, t('common:upload_image'))}
							</Button>
						</label>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
						<Button onClick={() => uploadConfig(customizedHome)} variant="outlined" disabled={reload}>
							{saveOrEdit}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default CustomizedHomeForm;
