import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { useEffect, useState } from 'react';
import TravelDestinationForm from 'components/TravelDestinationForm';
import { fetchDestination } from 'lib/models/destinations';
import { Box, CircularProgress } from '@mui/material';

function LocationEdition() {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [destination, setDestination] = useState<TravelDestination | null>(null);

	const { id } = useParams<{ id: string }>();

	const newUser = !!id && id === 'new';

	const title = loading
		? t('common:loading')
		: newUser
		? `${t('common:create')} ${t('common:location')}`
		: `${t('common:edit')} ${t('common:location')}`;

	const getDestination = async (_id: string) => {
		try {
			setLoading(true);
			const results = await fetchDestination(_id);
			setDestination(results);
			setLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (id && id !== 'new') {
			getDestination(id);
		}
	}, [id]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer sx={{ mb: 1 }}>
				{id && id !== 'new' ? (
					loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
							<CircularProgress disableShrink />
						</Box>
					) : (
						<TravelDestinationForm destinationEdit={destination} type="edit" />
					)
				) : (
					<TravelDestinationForm type="create" />
				)}
			</PaperContainer>
		</ScreenContainer>
	);
}

export default LocationEdition;
