import { useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router';

import { getStringFromData } from 'lib/helpers';

import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import { reducer } from 'lib/queryReducer';

import FullLayout from 'components/layouts/FullLayout/FullLayout';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import SearchToolbar from 'components/SearchToolbar/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable/ResponsiveTable';
import MobileItem from './MobileItem';
import CircleIcon from '@mui/icons-material/Circle';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Box from '@mui/material/Box/Box';
import { rowsPerPageOptions } from 'config/constants';
import usePaginatedData from 'hooks/usePaginatedData';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchContacts } from 'lib/models/contacts';

function ReservationsTable() {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);
	const navigate = useNavigate();

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: reservations,
		total,
		requestTotal,
	} = usePaginatedData(fetchContacts, query, '_id', resetMobileState);

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch]
	);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={reservations}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (reservation) => <MobileItem reservation={reservation} />,
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:email')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:location')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:status')}
								</TableCell>
								<TableCell align="left" padding="normal"></TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (reservation) => (
						<Tooltip title={t(`common:edit`)} arrow placement="top-start">
							<TableRow
								tabIndex={-1}
								key={reservation._id}
								onClick={
									() => navigate(`/reservations/${reservation._id}`)
									/*{
									if (setLocationEdit && setActiveTab) {
										setLocationEdit(reservation);
										setActiveTab('2');
									}
								}*/
								}
								sx={{
									'&:hover': {
										backgroundColor: 'lightgray',
										cursor: 'pointer',
									},
									backgroundColor: reservation.read ? '#d9d8d4' : '#ffff',
								}}
							>
								<TableCell>{reservation.name}</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{reservation.email && reservation.email}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{reservation.itemId}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{reservation.status}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{!reservation.read && <CircleIcon sx={{ color: 'red', height: '8px' }} />}
									</Box>
								</TableCell>
							</TableRow>
						</Tooltip>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

export default ReservationsTable;
