import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';

type Props = {
	tag: Tag;
	setTagEdit: Dispatch<SetStateAction<Tag | null>>;
};

function MobileItem({ tag, setTagEdit }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="tag-content" id="tag-header">
						<Typography>{tag.name}</Typography>
					</AccordionSummary>
					<AccordionDetails onClick={() => setTagEdit(tag)}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
						>
							{t('common:description')}: {tag.description}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
						>
							{t('common:show_in_screen').toUpperCase()}: {tag.show ? t(`common:yes`) : t(`common:not`)}
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
