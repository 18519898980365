import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import CustomizedHomeForm from 'components/CustomizedHomeForm';
import PaperContainer from 'components/containers/PaperContainer';
import ScreenContainer from 'components/containers/ScreenContainer';
import { getCustomizedHome } from 'lib/models/customizedHome';
import { enqueueSnackbarError } from 'lib/helpers';
import { useErrorParser } from 'hooks/useErrorParser';

function CustomizedHome() {
	const { t } = useTranslation();
	const [customizedHome, setCustomizedHome] = useState<CustomizedHome | null>(null);
	const [reload, setReload] = useState<boolean>(false);
	const errorParser = useErrorParser();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getCustomizedHome();
				setCustomizedHome(response);
			} catch (error) {
				const message = t('common:errorGetCustomizedHome');
				enqueueSnackbarError(message, errorParser);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ScreenContainer
			title={`${t('common:customized')} ${t('common:customized_home')}`}
			goBack={true}
			sx={{
				padding: 3,
				mb: 3,
			}}
		>
			<PaperContainer sx={{ mb: 1 }}>
				<CustomizedHomeForm
					setReload={setReload}
					reload={reload}
					customizedHome={customizedHome}
					setCustomizedHome={setCustomizedHome}
					type="create"
				/>
			</PaperContainer>
		</ScreenContainer>
	);
}

export default CustomizedHome;
