import { API_DOMAIN, ACTIVITIES_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchActivitySearchProps = {
	search?: string | null;
};

export type FetchActivitiesProps = FetchActivitySearchProps & PaginationProps;

export async function fetchActivity(props: FetchActivitiesProps): Promise<PaginateSource<Activity>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'name' || 'description' || 'including' || 'not_including' || 'price.currency']:
				props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Activity>>(`${API_DOMAIN}${ACTIVITIES_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function createActivity(activity: Activity): Promise<Activity> {
	const url = `${API_DOMAIN}${ACTIVITIES_URL}`;
	const response = await api.post<Activity>(url, { activity });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || activity;
}

export async function createActivityWithFiles(activity: Activity, files?: File[]): Promise<Activity> {
	const url = `${API_DOMAIN}${ACTIVITIES_URL}/image`;

	const formData = new FormData();
	formData.append('activity', JSON.stringify(activity));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}

	const response = await api.post<Activity>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || activity;
}

export async function updatedActivity(activity: Activity, _id: string) {
	const url = `${API_DOMAIN}${ACTIVITIES_URL}/${_id}`;
	const response = await api.patch(url, { activity });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function updatedActivityWithFiles(
	activityId: string,
	activity: Activity,
	files?: File[]
): Promise<Activity> {
	const url = `${API_DOMAIN}${ACTIVITIES_URL}/${activityId}/image`;

	const formData = new FormData();
	formData.append('activity', JSON.stringify(activity));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}

	const response = await api.patch<Activity>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || activity;
}
