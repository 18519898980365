import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function AppConfigs({ selected, onSelect }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/aboutUs"
			selected={selected === MenuItem.AboutUs}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.AboutUs)}
		>
			<ListItemIcon>
				<EmojiPeopleIcon />
			</ListItemIcon>
			<ListItemText primary={t('common:about_us')} />
		</ListItem>
	);
}
