import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Button, Checkbox, Divider, Grid, TextField, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { DELETEITEMS, provincesArgentina } from 'config/constants';
import { fetchFiles, fetchGenericCombined } from 'lib/models/generics';
import {
	createDestination,
	createDestinationWithFiles,
	deleteDestination,
	updatedDestination,
	updatedDestinationWithFiles,
} from 'lib/models/destinations';
import { useErrorParser } from 'hooks/useErrorParser';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { enqueueSnackbarError } from 'lib/helpers';
import { initialStateDestination } from 'types/constants';
import ContactDestination from './ContactDestination';
import DestinationChips from './ChipsDestination';
import FilesDestination from './FilesDestination';
import { Save as SaveIcon, Delete as DeleteIcon } from '@mui/icons-material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
import AvailabilityDestination from './AvailabilityDestination';
import { validateEmail } from 'lib/validateEmail';

type Props = {
	destinationEdit?: TravelDestination | null;
	type: 'create' | 'edit';
};

const TravelDestinationForm = ({ destinationEdit, type }: Props) => {
	const { t } = useTranslation();
	const [reload, setReload] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	const [showImages, setShowImages] = useState<string[] | null>(null);
	const [resultsCombined, setResultsCombined] = useState<GenericCombined | null>(null);
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const [destination, setDestination] = useState<TravelDestination | null>(
		type === 'edit' && destinationEdit ? destinationEdit : initialStateDestination
	);

	const navigate = useNavigate();

	const getResultsCombined = async () => {
		try {
			const results = await fetchGenericCombined();
			setResultsCombined(results);
		} catch (err) {
			console.warn(err);
		}
	};

	function updateDestinationKey(
		mainKey: keyof TravelDestination,
		subKey?: keyof ItemLocation | keyof Contact | keyof StateLocation
	) {
		if (subKey === undefined) {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setDestination((prevDestination) => {
					if (!prevDestination) {
						return null;
					}

					if (mainKey === 'activity') {
						const updatedActivity = [...(prevDestination.activity || []), ev.target.value];
						return { ...prevDestination, [mainKey]: updatedActivity } as TravelDestination;
					}
					if (mainKey === 'tag') {
						prevDestination.province = '';
					}
					return { ...prevDestination, [mainKey]: ev.target.value } as TravelDestination;
				});
			};
		} else {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setDestination((prevDestination) => {
					if (!prevDestination) {
						return null;
					}
					// If subKey is defined, it updates a nested property

					if (subKey === 'open') {
						return Object.assign({}, prevDestination, {
							[mainKey]: Object.assign({}, prevDestination[mainKey], {
								[subKey]: !prevDestination.stateLocation?.open,
							}),
						}) as TravelDestination;
					}

					return Object.assign({}, prevDestination, {
						[mainKey]: Object.assign({}, prevDestination[mainKey], {
							[subKey]: ev.target.value,
						}),
					}) as TravelDestination;
				});
			};
		}
	}

	const handleImageRemove = (index: number, edit?: string) => {
		if (edit && destination?.images) {
			const updatedImages = [...(destination?.images || [])];
			const updatedViewImages = [...(showImages || [])];
			updatedImages.splice(index, 1);
			updatedViewImages.splice(index, 1);
			setDestination((prevDestination: TravelDestination | null) => {
				if (!prevDestination) {
					return null;
				}
				return {
					...prevDestination,
					images: updatedImages,
				};
			});
			setShowImages(updatedViewImages);
		} else {
			const updatedImages = [...selectedImages];
			updatedImages.splice(index, 1);
			setSelectedImages(updatedImages);
		}
	};

	async function add(destination: TravelDestination): Promise<TravelDestination> {
		if (!selectedImages || selectedImages.length < 1) {
			return await createDestination(destination);
		} else {
			return await createDestinationWithFiles(destination, selectedImages);
		}
	}

	async function updated(destination: TravelDestination) {
		if (!destination._id) {
			return;
		}
		if (!selectedImages || selectedImages.length < 1) {
			return await updatedDestination(destination, destination._id);
		} else {
			return await updatedDestinationWithFiles(destination._id, destination, selectedImages);
		}
	}

	async function uploadDestination(destination: TravelDestination | null) {
		if (!destination) {
			return;
		}

		const cleanDestination: TravelDestination = { ...destination };

		if (cleanDestination.accommodation && cleanDestination.accommodation._id === '') {
			delete cleanDestination.accommodation;
		}

		if (cleanDestination.activity && Array.isArray(cleanDestination.activity)) {
			cleanDestination.activity = cleanDestination.activity.filter((activityItem) => activityItem._id !== '');
		}

		if (cleanDestination.tag && cleanDestination.tag._id === '') {
			delete cleanDestination.tag;
		}

		try {
			if (!destination._id) {
				await add(cleanDestination);
				setDestination(initialStateDestination);
			} else {
				await updated(cleanDestination);
			}
			successSnackbar();
			navigate('/locations');
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	const loadImages = async (files: string[] | null) => {
		try {
			if (files === null) {
				return;
			}
			const fileResponse = await fetchFiles(files);
			if (fileResponse) {
				const images = fileResponse.map((fileData) => `data:image/${fileData.type};base64,${fileData.file}`);
				setShowImages(images);
			}
		} catch (error) {
			console.warn(error);
		}
	};

	async function onDeleteDestination(confirm: boolean, id?: string) {
		if (!id) {
			return;
		}

		try {
			if (confirm) {
				setLoading(true);
				await deleteDestination(id);
				navigate('/locations');
			}
		} catch (error) {
			enqueueSnackbarError(error);
		}
		setLoading(false);
		setConfirmDelete(false);
	}

	useEffect(() => {
		getResultsCombined();
		setReload(false);
	}, [reload, setReload]);

	useEffect(() => {
		if (
			destination?.images &&
			(destination?.images.length > 1 || (destination?.images[0] !== '' && destination?.images.length > 0))
		) {
			loadImages(destination.images);
		}
	}, [destination?.images]);

	const provinces = destination?.tag?.province ?? [];

	return (
		<>
			<Box
				sx={{
					flexGrow: 1,
					padding: 3,
					overflowY: 'auto',
				}}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							id="name"
							label={t('common:name')}
							value={destination?.name}
							fullWidth={true}
							onChange={updateDestinationKey('name')}
							error={destination?.name === ''}
							helperText={destination?.name === '' ? t('system:emptyField') : ''}
							variant="outlined"
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						{/* Autocomplete for Province */}
						{/* Replace options with your actual data */}

						<Autocomplete
							disablePortal
							fullWidth
							value={destination?.province}
							onChange={(event, newValue) => {
								const syntheticEvent = {
									target: { value: newValue },
								} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
								updateDestinationKey('province')(syntheticEvent);
							}}
							options={
								destination?.tag && destination.tag._id && destination.tag._id !== '' && provinces.length > 0
									? provinces
									: provincesArgentina
							}
							sx={{ backgroundColor: 'white' }}
							renderInput={(params) => <TextField {...params} fullWidth label={t('common:province')} />}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="address"
							label={t('common:address')}
							value={destination?.address}
							fullWidth={true}
							onChange={updateDestinationKey('address')}
							variant="outlined"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							id="meeting_point"
							label={t('common:meeting_point')}
							value={destination?.meeting_point}
							fullWidth={true}
							onChange={updateDestinationKey('meeting_point')}
							variant="outlined"
						/>
					</Grid>

					{/*<MapForm destination={destination} updateDestinationKey={updateDestinationKey} />*/}

					<DestinationChips
						destination={destination}
						setDestination={setDestination}
						setReload={setReload}
						reload={reload}
						updateDestinationKey={updateDestinationKey}
						resultsCombined={resultsCombined}
					/>
					<Grid item xs={12}>
						<TextField
							id={`description`}
							label={t(`common:description`)}
							value={destination?.description}
							multiline
							fullWidth
							onChange={updateDestinationKey('description')}
							rows={5}
							sx={{ mb: 3, backgroundColor: '#fff' }}
						/>
					</Grid>
					<Typography variant="body1">{t(`common:openingDate`)}</Typography>
					<Divider sx={{ width: '100%', my: 1, mt: 2 }} />
					<Grid item xs={6}>
						<TextField
							label={t('selectOpeningDate')}
							type="date"
							variant="outlined"
							value={destination?.stateLocation?.date}
							onChange={updateDestinationKey('stateLocation', 'date')}
							fullWidth
							InputLabelProps={{
								shrink: true,
							}}
							disabled={destination?.stateLocation?.open}
							sx={{ mb: 3 }}
						/>
					</Grid>
					<Grid item xs={6}>
						<Checkbox
							checked={destination?.stateLocation?.open}
							onChange={updateDestinationKey('stateLocation', 'open')}
						/>
						{t(`common:opened`)}
					</Grid>
					<AvailabilityDestination availability={destination?.operatingHours} setDestination={setDestination} />

					<ContactDestination destination={destination} updateDestinationKey={updateDestinationKey} />
					<Grid item xs={12}>
						<Typography variant="body1">{t(`common:links`)}</Typography>
						<Divider sx={{ width: '100%', my: 1 }} />
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="url_maps"
							label={t('common:url_maps')}
							value={destination?.maps}
							placeholder='<iframe src="https://www.google.com/maps/embed?..." width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
							fullWidth={true}
							variant="outlined"
							onChange={updateDestinationKey('maps')}
							sx={{ mb: 3, backgroundColor: 'white' }}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="url_instagram"
							label={t('common:url_instagram')}
							value={destination?.instagram}
							placeholder='<blockquote class="instagram-media"...>...</blockquote><script async src="//www.instagram.com/embed.js"></script>'
							fullWidth={true}
							variant="outlined"
							onChange={updateDestinationKey('instagram')}
							sx={{ mb: 3, backgroundColor: 'white' }}
						/>
					</Grid>
					<FilesDestination
						destination={destination}
						updateDestinationKey={updateDestinationKey}
						selectedImages={selectedImages}
						showImages={showImages}
						handleImageRemove={handleImageRemove}
						setSelectedImages={setSelectedImages}
						type={type}
					/>
					<Divider sx={{ width: '100%', my: 1, mt: 2 }} />
					<Grid item xs={12}>
						<Button
							disabled={
								!destination?.contact?.email ||
								destination?.contact?.email === '' ||
								!destination?.name ||
								destination?.name === '' ||
								!validateEmail(destination?.contact?.email)
							}
							variant="contained"
							component="label"
							size="large"
							sx={{ m: 1, width: '100%' }}
							startIcon={<SaveIcon />}
							onClick={() => uploadDestination(destination)}
						>
							{t('common:save')}
						</Button>
					</Grid>
					{type === 'edit' && DELETEITEMS && destination?._id && (
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="secondary"
								size="large"
								sx={{ m: 1, width: '100%' }}
								startIcon={<DeleteIcon />}
								onClick={() => setConfirmDelete(true)}
							>
								{t('common:delete')}
							</Button>
						</Grid>
					)}
				</Grid>
			</Box>
			{confirmDelete && (
				<ConfirmationDialog
					title={`${t('common:delete')} ${t('common:location')}`}
					description={t('common:deleteText') || ''}
					onClose={(confirm: boolean) => {
						onDeleteDestination(confirm, destination?._id);
					}}
					loading={loading}
				/>
			)}
		</>
	);
};

export default TravelDestinationForm;
