export const DRAWER_WIDTH = 240;

export const APPBAR_MAX_HEIGHT = 128;

// IF YOU ARE IN DEV MODE API_DOMAIN IS SET IN .env
// @ts-ignore
let API_DOMAIN = `${process.env.REACT_APP_API_DOMAIN}`;

// Check if the last index is a /, if not add it:
if (API_DOMAIN.substring(API_DOMAIN.length - 1) !== '/') {
	API_DOMAIN = `${API_DOMAIN}/`;
}

const AUTH_URL = 'auth';

const LOGIN_URL = `${AUTH_URL}/login`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_URL = `${AUTH_URL}/password-recovery`; // axios baseURL is ${API_DOMAIN}

const RECOVERY_CONFIRM_URL = `${AUTH_URL}/password-recovery/confirm`; // axios baseURL is ${API_DOMAIN}

const ME_URL = `${AUTH_URL}/me`;

const FIRST_TIME_URL = `${AUTH_URL}/first-time`;

const USERS_URL = 'users';

const PROFILE_URL = 'profile';

const ADMIN_URL = 'admin';

const DESTINATIONS_URL = 'destinations';

const CONTACTS_URL = 'contacts';

const ACCOMMODATIONS_URL = 'accommodations';

const ACTIVITIES_URL = 'activities';

const TAGS_URL = 'tags';
const CUSTOMIZED_HOME_URL = 'customized-home';

const CONFIGS_URL = 'configs';
const ABOUT_US_URL = 'about-us';
const GENERICS_URL = 'generics';

const rowsPerPageOptions = [5, 25, 50, 100, 500, 1000];

const defaultRowsPerPage = 5;

// @ts-ignore
const PANEL_MANUAL_URL = `${process.env.REACT_APP_PANEL_MANUAL_URL}`;
// @ts-ignore
const IT_MANUAL_URL = `${process.env.REACT_APP_IT_MANUAL_URL}`;

const SUPPORTED_LANGUAGES: SupportedLanguage[] = ['es', 'en'];

const DELETEITEMS = true; //the buttons for deleting items (tags, activities, etc) will be disabled.
// @ts-ignore
const NODE_ENV = `${process.env.NODE_ENV || ''}`;

export {
	API_DOMAIN,
	AUTH_URL,
	CONFIGS_URL,
	ME_URL,
	USERS_URL,
	LOGIN_URL,
	DESTINATIONS_URL,
	ACCOMMODATIONS_URL,
	ACTIVITIES_URL,
	GENERICS_URL,
	TAGS_URL,
	FIRST_TIME_URL,
	rowsPerPageOptions,
	defaultRowsPerPage,
	PANEL_MANUAL_URL,
	IT_MANUAL_URL,
	PROFILE_URL,
	SUPPORTED_LANGUAGES,
	ADMIN_URL,
	RECOVERY_URL,
	RECOVERY_CONFIRM_URL,
	NODE_ENV,
	DELETEITEMS,
	ABOUT_US_URL,
	CUSTOMIZED_HOME_URL,
	CONTACTS_URL,
};

export const provincesArgentina: string[] = [
	'Buenos Aires',
	'Catamarca',
	'Chaco',
	'Chubut',
	'Córdoba',
	'Corrientes',
	'Entre Ríos',
	'Formosa',
	'Jujuy',
	'La Pampa',
	'La Rioja',
	'Mendoza',
	'Misiones',
	'Neuquén',
	'Río Negro',
	'Salta',
	'San Juan',
	'San Luis',
	'Santa Cruz',
	'Santa Fe',
	'Santiago del Estero',
	'Tierra del Fuego, Antártida e Islas del Atlántico Sur',
	'Tucumán',
];

export const initialStateConfig = { instagram: '', linkedin: '', conditions: '', privacy: '' };
export const initialStateAboutUs = {
	title1: '',
	title2: '',
	text: '',
	button: '',
	titleForm: '',
	textForm: '',
	urlPdf: '',
};

export const initialStateCustomizedHome = {
	title: '',
	text: '',
};
