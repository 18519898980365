import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { createOrUpdatedConfig, createOrUpdatedConfigWithFiles } from 'lib/models/configs';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { initialStateConfig } from 'config/constants';
import FilesConfig from './FilesConfig';
import { fetchFiles } from 'lib/models/generics';

type Props = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	config: AppConfig | null;
	setConfig: Dispatch<SetStateAction<AppConfig | null>>;
	//setAssignConfig?: (item?: AppConfig) => void;
};

function ConfigForm({ setReload, reload, config, setConfig }: Props) {
	const type = 'create';
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [showImages, setShowImages] = useState<string[] | null>(null);
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);

	function updateConfigKey(mainKey: keyof AppConfig) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setConfig((prevConfig) => {
				if (!prevConfig) {
					return initialStateConfig;
				}

				return { ...prevConfig, [mainKey]: ev.target.value } as AppConfig;
			});
		};
	}

	async function uploadConfig(config: AppConfig | null) {
		if (!config) {
			return;
		}

		try {
			if (setReload) {
				setReload(true);
			}
			await addOrUpdateConfig(config);
			successSnackbar();
			setSelectedImages([]);
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
		setReload && setReload(false);
	}

	async function addOrUpdateConfig(config: AppConfig): Promise<AppConfig> {
		if (!selectedImages || selectedImages.length < 1) {
			return await createOrUpdatedConfig(config);
		} else {
			const result = await createOrUpdatedConfigWithFiles(config, selectedImages);
			if (result && result !== null) setConfig(result);
			return result;
		}
		//return await createOrUpdatedConfig(config);
	}

	const handleImageRemove = (index: number, edit?: string) => {
		if (edit && config?.images) {
			const updatedImages = [...(config?.images || [])];
			const updatedViewImages = [...(showImages || [])];
			updatedImages.splice(index, 1);
			updatedViewImages.splice(index, 1);
			setConfig((prevDestination: AppConfig | null) => {
				if (!prevDestination) {
					return null;
				}
				return {
					...prevDestination,
					images: updatedImages,
				};
			});
			setShowImages(updatedViewImages);
		} else {
			const updatedImages = [...selectedImages];
			updatedImages.splice(index, 1);
			setSelectedImages(updatedImages);
		}
	};

	const loadImages = async (files: string[] | null) => {
		try {
			if (files === null) {
				return;
			}
			const fileResponse = await fetchFiles(files);
			if (fileResponse) {
				const images = fileResponse.map((fileData) => `data:image/${fileData.type};base64,${fileData.file}`);
				setShowImages(images);
			}
		} catch (error) {
			console.warn(error);
		}
	};

	useEffect(() => {
		if (config?.images && (config?.images.length > 1 || (config?.images[0] !== '' && config?.images.length > 0))) {
			loadImages(config.images);
		}
	}, [config?.images]);

	return (
		<Box
			sx={{
				flexGrow: 1,
				padding: 3,
				overflowY: 'auto',
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<TextField
						id="instagram"
						label={t('common:Instagram')}
						value={config?.instagram}
						fullWidth={true}
						onChange={updateConfigKey('instagram')}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`linkedin`}
						label={t(`common:Linkedin`)}
						fullWidth
						value={config?.linkedin}
						onChange={updateConfigKey('linkedin')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`privacy`}
						label={t(`common:privacy`)}
						fullWidth
						value={config?.privacy}
						onChange={updateConfigKey('privacy')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`condition`}
						label={t(`common:conditions`)}
						fullWidth
						value={config?.conditions}
						onChange={updateConfigKey('conditions')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<FilesConfig
					selectedImages={selectedImages}
					showImages={showImages}
					handleImageRemove={handleImageRemove}
					setSelectedImages={setSelectedImages}
					type={'edit'}
				/>
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
						<Button onClick={() => uploadConfig(config)} variant="outlined" disabled={reload}>
							{saveOrEdit}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default ConfigForm;
