import { Dispatch, SetStateAction, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
//import { useNavigate } from 'react-router';

import { getStringFromData } from 'lib/helpers';

import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import { reducer } from 'lib/queryReducer';

import FullLayout from 'components/layouts/FullLayout/FullLayout';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import SearchToolbar from 'components/SearchToolbar/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable/ResponsiveTable';
import MobileItem from './MobileItem';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Box from '@mui/material/Box/Box';
import { rowsPerPageOptions } from 'config/constants';
import usePaginatedData from 'hooks/usePaginatedData';
import { fetchDestinations } from 'lib/models/destinations';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

type Props = {
	setLocationEdit?: Dispatch<SetStateAction<TravelDestination | null>>;
	setActiveTab?: Dispatch<SetStateAction<string>>;
};

function LocationTable({ setLocationEdit, setActiveTab }: Props) {
	const { t } = useTranslation();

	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);
	const navigate = useNavigate();

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: locations,
		total,
		requestTotal,
	} = usePaginatedData(fetchDestinations, query, '_id', resetMobileState);

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch]
	);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	return (
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={locations}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (location) => <MobileItem location={location} />,
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:description')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:province')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:address')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:openingDate')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (location) => (
						<Tooltip title={t(`common:edit`)} arrow placement="top-start">
							<TableRow
								tabIndex={-1}
								key={location._id}
								onClick={
									() => navigate(`/locations/${location._id}`)
									/*{
									if (setLocationEdit && setActiveTab) {
										setLocationEdit(location);
										setActiveTab('2');
									}
								}*/
								}
								sx={{
									'&:hover': {
										backgroundColor: 'lightgray',
										cursor: 'pointer',
									},
								}}
							>
								<TableCell>{location.name}</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{location.description && location.description.length > 45
											? `${location.description.slice(0, 47)}...`
											: location.description}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{location.province}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{location.address}
									</Box>
								</TableCell>
								<TableCell sx={{ mb: 1 }}>
									<Box
										sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
									>
										{location?.stateLocation?.open
											? t(`common:opened`)
											: location?.stateLocation?.date
											? moment(location?.stateLocation?.date).format('DD/MM/YYYY')
											: ''}
									</Box>
								</TableCell>
							</TableRow>
						</Tooltip>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>
		</FullLayout>
	);
}

export default LocationTable;
