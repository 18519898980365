import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

type Props = {
	location: TravelDestination;
	setLocationEdit?: Dispatch<SetStateAction<TravelDestination | null>>;
	setActiveTab?: Dispatch<SetStateAction<string>>;
};

function MobileItem({ location, setLocationEdit, setActiveTab }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="location-content" id="location-header">
						<Typography>{location.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
							onClick={
								() => navigate(`/locations/${location._id}`) /*{
								if (setLocationEdit && setActiveTab) {
									setLocationEdit(location);
									setActiveTab('2');
								}
							}*/
							}
						>
							{t('common:description')}: {location.description}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:province')}: {location.province}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:address')}: {location.address}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:openingDate')}:{' '}
							{location?.stateLocation?.open
								? t(`common:opened`)
								: location?.stateLocation?.date
								? moment(location?.stateLocation?.date).format('DD/MM/YYYY')
								: ''}
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
