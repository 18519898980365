import { Dialog, DialogContent } from '@mui/material';
type Props = {
	open: boolean;
	close: () => void;
	showImage: string;
};
const PreviewImage = ({ open, close, showImage }: Props) => {
	return (
		<Dialog open={open} onClose={close}>
			<DialogContent>
				{/*selectedImageIndex !== null && showImages && (
                    <img src={showImages[selectedImageIndex]} alt="" style={{ maxWidth: '100%' }} />
                )*/}
				<img src={showImage} alt="" style={{ maxWidth: '100%' }} />
			</DialogContent>
		</Dialog>
	);
};

export default PreviewImage;
