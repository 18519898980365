import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import TagForm from 'components/TagForm';
import { useTranslation } from 'react-i18next';
type TaggedObject<T> = T & { tag: Tag };
type Props<T> = {
	destination: TravelDestination | null;
	updateDestinationKey: (mainKey: keyof TravelDestination, subKey?: keyof ItemLocation | keyof Contact) => any;
	resultsCombined: GenericCombined | null;
	reload: boolean;
	setReload: Dispatch<SetStateAction<boolean>>;
	setDestination: Dispatch<SetStateAction<TaggedObject<T> | null>> | Dispatch<SetStateAction<TravelDestination | null>>;
};

function TagField<T>({
	destination,
	updateDestinationKey,
	resultsCombined,
	reload,
	setReload,
	setDestination,
}: Props<T>) {
	const { t } = useTranslation();
	const [newTag, setNewTag] = useState<Tag | null>(null);
	return (
		<>
			<Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
				<Autocomplete
					disablePortal
					fullWidth
					value={destination?.tag?._id}
					onChange={(event, newValue) => {
						const selectedTag = resultsCombined?.tags.find((tag: Tag) => tag._id === newValue);
						const syntheticEvent = {
							target: { value: selectedTag } as unknown as HTMLInputElement | HTMLTextAreaElement,
						} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
						updateDestinationKey('tag')(syntheticEvent);
					}}
					options={(resultsCombined?.tags ?? []).map((tag: Tag) => tag?._id)}
					getOptionLabel={(tagId) => {
						const tag = resultsCombined?.tags.find((t) => t._id === tagId);
						return tag ? tag.name : '';
					}}
					sx={{ mb: 1, backgroundColor: 'white' }}
					renderInput={(params) => <TextField {...params} fullWidth label={t('common:tag_plural')} />}
				/>
				<IconButton onClick={() => setNewTag({} as Tag)} color="primary" aria-label="add activity">
					<AddIcon />
				</IconButton>
			</Grid>
			<TagForm
				tag={newTag}
				setTag={setNewTag}
				setReload={setReload}
				reload={reload}
				setAssignTag={setDestination}
				//setAssignTag={getResultsCombined}
				type="create"
			/>
		</>
	);
}

export default TagField;
