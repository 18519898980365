import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AccommodationForm from 'components/AccommodationForm';
import { useTranslation } from 'react-i18next';
type AccommodationObject<T> = T & { accommodation: Accommodation };
type Props<T> = {
	destination: TravelDestination | null;
	updateDestinationKey: (mainKey: keyof TravelDestination, subKey?: keyof ItemLocation | keyof Contact) => any;
	resultsCombined: GenericCombined | null;
	reload: boolean;
	setReload: Dispatch<SetStateAction<boolean>>;
	setDestination:
		| Dispatch<SetStateAction<AccommodationObject<T> | null>>
		| Dispatch<SetStateAction<TravelDestination | null>>;
};
function AccommodationField<T>({
	destination,
	updateDestinationKey,
	resultsCombined,
	setReload,
	reload,
	setDestination,
}: Props<T>) {
	const { t } = useTranslation();
	const [newAccommodation, setNewAccommodation] = useState<Accommodation | null>(null);
	return (
		<>
			<Grid item xs={12} sm={4} sx={{ display: 'flex' }}>
				<Autocomplete
					disablePortal
					fullWidth
					value={destination?.accommodation?._id}
					onChange={(event, newValue) => {
						const selectedAccommodation = resultsCombined?.accommodations.find(
							(accommodation: Accommodation) => accommodation._id === newValue
						);
						const syntheticEvent = {
							target: { value: selectedAccommodation } as unknown as HTMLInputElement | HTMLTextAreaElement,
						} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
						updateDestinationKey('accommodation')(syntheticEvent);
					}}
					options={(resultsCombined?.accommodations ?? []).map((accommodation: Accommodation) => accommodation?._id)}
					getOptionLabel={(accommodationId) => {
						const accommodation = resultsCombined?.accommodations.find((a) => a._id === accommodationId);
						return accommodation ? accommodation.name : '';
					}}
					sx={{ mb: 1, backgroundColor: 'white' }}
					renderInput={(params) => <TextField {...params} fullWidth label={t('common:accommodation')} />}
				/>
				<IconButton
					onClick={() => setNewAccommodation({ including: [''], not_including: [''] } as Accommodation)}
					color="primary"
					aria-label="add activity"
				>
					<AddIcon />
				</IconButton>
			</Grid>
			<AccommodationForm
				setReload={setReload}
				reload={reload}
				accommodation={newAccommodation}
				setAccommodation={setNewAccommodation}
				setAssignAccommodation={setDestination}
				type="create"
			/>
		</>
	);
}

export default AccommodationField;
