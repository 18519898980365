import { API_DOMAIN, ABOUT_US_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function getAboutUs(): Promise<AboutUs> {
	const url = `${API_DOMAIN}${ABOUT_US_URL}`;
	const response = await api.get<AboutUs>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	return response.data;
}

export async function createOrUpdatedAboutUs(aboutUs: AboutUs): Promise<AboutUs> {
	const url = `${API_DOMAIN}${ABOUT_US_URL}`;
	const response = await api.post<AboutUs>(url, { aboutUs });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || aboutUs;
}

export async function createOrUpdatedAboutUsWithFiles(aboutUs: AboutUs, files?: File[]): Promise<AboutUs> {
	const url = `${API_DOMAIN}${ABOUT_US_URL}/image`;
	const formData = new FormData();
	formData.append('aboutUs', JSON.stringify(aboutUs));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}
	const response = await api.post<AboutUs>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || aboutUs;
}
