import { Box, Button, Grid, IconButton, Stack } from '@mui/material';
import { Item } from 'types/constants';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';

type Props = {
	images: File[];
	handleImageRemove: (index: number) => void;
	setSelectedImages: Dispatch<SetStateAction<File[]>>;
};

const BoxImagesWithoutPreview = ({ images, handleImageRemove, setSelectedImages }: Props) => {
	const { t } = useTranslation();

	const handleImageChange = (files: FileList | null) => {
		if (files) {
			const newImagesArray = Array.from(files);
			setSelectedImages((prevImages) => [...prevImages, ...newImagesArray]);
		}
	};

	return (
		<>
			{images.length > 0 && (
				<Grid item xs={12}>
					<Box
						sx={{
							borderWidth: 1,
							borderRadius: 3,
							minHeight: 50,
							maxHeight: 400,
							margin: 'auto',
							mb: 2,
							py: 2,
							px: 2,
							backgroundColor: '#f9f9f9',
							borderColor: '#d3d3d3',
							borderStyle: 'solid',
							overflowY: 'auto',
							maxWidth: '100%',
							overflowX: 'auto',
						}}
					>
						<Stack direction="row" sx={{ flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
							{images.map((image, index) => (
								<Item key={index} sx={{ mb: 2, mr: 1 }}>
									{image.name}
									<IconButton
										sx={{
											background: 'rgba(255, 255, 255, 0.8)',
											fontSize: '14px',
											width: '24px',
											height: '24px',
										}}
										onClick={() => handleImageRemove(index)}
									>
										<CloseIcon sx={{ fontSize: '16px' }} />
									</IconButton>
								</Item>
							))}
						</Stack>
					</Box>
				</Grid>
			)}

			<Grid item xs={12}>
				<Button variant="contained" component="label">
					{t('common:upload_image')}
					<input type="file" hidden accept="image/*" multiple onChange={(e) => handleImageChange(e.target.files)} />
				</Button>
			</Grid>
		</>
	);
};

export default BoxImagesWithoutPreview;
