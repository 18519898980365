import { useTranslation } from 'react-i18next';
import FluidContainer from 'components/containers/FluidContainer';
import ActivitiesTable from 'components/ActivitiesTable/ActivitiesTable';
import CreateIcon from 'components/icons/CreateIcon/CreateIcon';
import { useState } from 'react';

import ActivityForm from 'components/ActivityForm';

function Activities() {
	const { t } = useTranslation();
	const [activity, setActivity] = useState<Activity | null>(null);
	const [reload, setReload] = useState<boolean>(false);

	return (
		<FluidContainer title={t('common:activity_plural')} goBack={true}>
			<CreateIcon
				onCreate={() =>
					setActivity({
						name: '',
						description: '',
						including: [''],
						not_including: [''],
						price: {
							value: 0,
							currency: 'USD',
							persons: 1,
						},
					})
				}
			/>
			<ActivitiesTable reload={reload} setReload={setReload} />
			<ActivityForm setReload={setReload} reload={reload} activity={activity} setActivity={setActivity} type="create" />
		</FluidContainer>
	);
}

export default Activities;
