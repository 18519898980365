import { Autocomplete, Box, Button, TextField } from '@mui/material';
import AddItem from 'components/AddItem/AddItem';
import InputListManager from 'components/InputListManager/InputListManager';
import { useErrorParser } from 'hooks/useErrorParser';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { cleanArray } from 'lib/cleanArray';
import { createAccommodation, updatedAccommodation } from 'lib/models/accommodations';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbarError } from 'lib/helpers';

type AccommodationObject<T> = T & { accommodation: Accommodation };
type Props<T> = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	accommodation: Accommodation | null;
	setAccommodation: Dispatch<SetStateAction<Accommodation | null>>;
	setAssignAccommodation?:
		| Dispatch<SetStateAction<AccommodationObject<T> | null>>
		| Dispatch<SetStateAction<TravelDestination | null>>;
	type: 'create' | 'edit';
};

const currencies: Currency[] = ['USD', 'ARS', 'BRL'];

function AccommodationForm<T>({
	setReload,
	reload,
	accommodation,
	setAccommodation,
	setAssignAccommodation,
	type,
}: Props<T>) {
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);
	const createOrEdit = t(type === 'create' ? `common:create` : `common:edit`);

	function updateAccommodationKey(mainKey: keyof Accommodation, subKey?: keyof Price) {
		if (subKey === undefined) {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setAccommodation({ ...(accommodation || {}), [mainKey]: ev.target.value } as Accommodation);
			};
		} else {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setAccommodation((prevAccommodation) => {
					if (!prevAccommodation) {
						return null;
					}
					// If subKey is defined, it updates a nested property
					return Object.assign({}, prevAccommodation, {
						[mainKey]: Object.assign({}, prevAccommodation[mainKey], {
							[subKey]: ev.target.value,
						}),
					}) as Accommodation;
				});
			};
		}
	}
	async function updated(accommodation: Accommodation) {
		if (!accommodation._id) {
			return;
		}
		await updatedAccommodation(accommodation, accommodation._id);
	}

	async function add(accommodation: Accommodation): Promise<Accommodation> {
		return await createAccommodation(accommodation);
	}

	async function AddAccommodation(accommodation: Accommodation | null) {
		if (!accommodation) {
			return;
		}

		if (accommodation.including && accommodation.including.length > 1) {
			accommodation.including = cleanArray(accommodation.including);
		}

		if (accommodation.not_including && accommodation.not_including.length > 1) {
			accommodation.not_including = cleanArray(accommodation.not_including);
		}

		try {
			//await createAccommodation(accommodation);
			if (!accommodation._id) {
				const resultAccommodation = await add(accommodation);
				setAssignAccommodation &&
					setAssignAccommodation((prevDestination: AccommodationObject<any> | null) => {
						if (prevDestination) {
							return {
								...prevDestination,
								accommodation: resultAccommodation,
							};
						}
						return {
							accommodation: resultAccommodation,
						};
					});
			} else {
				await updated(accommodation);
			}
			setAccommodation(null);
			if (setReload) {
				setReload(true);
			}
			successSnackbar();
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	return (
		<AddItem
			title={`${createOrEdit} ${t('common:accommodation').toLowerCase()}`}
			open={!!accommodation}
			onClose={() => setAccommodation(null)}
		>
			<TextField
				id="name"
				label={t('common:name')}
				value={accommodation?.name}
				fullWidth={true}
				variant="outlined"
				onChange={updateAccommodationKey('name')}
				sx={{ mb: 3 }}
				error={accommodation?.name === ''}
				helperText={accommodation?.name === '' ? t('system:emptyField') : ''}
			/>
			<TextField
				id={`description`}
				label={t(`common:description`)}
				multiline
				fullWidth
				rows={5}
				value={accommodation?.description}
				onChange={updateAccommodationKey('description')}
				sx={{ backgroundColor: '#fff' }}
			/>
			<InputListManager<Accommodation>
				item={accommodation}
				setItem={setAccommodation}
				label={t('common:including')}
				identifier="including"
			/>
			<InputListManager<Accommodation>
				item={accommodation}
				setItem={setAccommodation}
				label={t('common:not_including')}
				identifier="not_including"
			/>
			<Box
				sx={{
					borderWidth: 2,
					borderRadius: 4,
					minHeight: 50,
					maxHeight: 400,
					width: '100%',
					mb: 2,
					py: 2,
					px: 2,
					backgroundColor: '#f0f0f0',
					borderColor: '#d3d3d3',
					borderStyle: 'solid',
					overflowY: 'auto',
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<Autocomplete
						disablePortal
						fullWidth
						value={accommodation?.price?.currency}
						onChange={(event, newValue) => {
							const syntheticEvent = {
								target: { value: newValue },
							} as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
							updateAccommodationKey('price', 'currency')(syntheticEvent);
						}}
						options={currencies}
						sx={{ mb: 1, backgroundColor: 'white' }}
						renderInput={(params) => <TextField {...params} label={t('common:currency')} />}
					/>
				</Box>
				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<TextField
						label={t('common:price')}
						value={accommodation?.price?.value}
						fullWidth={true}
						variant="outlined"
						type="number"
						onChange={updateAccommodationKey('price', 'value')}
						InputProps={{ inputProps: { min: 1 } }}
						sx={{ mb: 1, backgroundColor: 'white' }}
					/>
				</Box>

				<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
					<TextField
						label={t('common:persons')}
						value={accommodation?.price?.persons}
						fullWidth={true}
						variant="outlined"
						type="number"
						onChange={updateAccommodationKey('price', 'persons')}
						InputProps={{ inputProps: { min: 1 } }}
						sx={{ mb: 1, backgroundColor: 'white' }}
					/>
				</Box>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
				<Button
					onClick={() => AddAccommodation(accommodation)}
					variant="outlined"
					disabled={accommodation?.name === '' || reload}
				>
					{saveOrEdit}
				</Button>
			</Box>
		</AddItem>
	);
}

export default AccommodationForm;
