import { useTranslation } from 'react-i18next';
import FluidContainer from 'components/containers/FluidContainer';
import TagsTable from 'components/TagsTable/TagsTable';
import CreateIcon from 'components/icons/CreateIcon/CreateIcon';
import { useState } from 'react';
import TagForm from 'components/TagForm';

function Tags() {
	const { t } = useTranslation();
	const [tag, setTag] = useState<Tag | null>(null);
	const [reload, setReload] = useState<boolean>(false);

	return (
		<FluidContainer title={t('common:tag_plural')} goBack={true}>
			<CreateIcon
				onCreate={() =>
					setTag({
						name: '',
						description: '',
						image: '',
						show: false,
					})
				}
			/>
			<TagsTable reload={reload} setReload={setReload} />
			<TagForm setReload={setReload} reload={reload} tag={tag} setTag={setTag} type="create" />
		</FluidContainer>
	);
}

export default Tags;
