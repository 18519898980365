import { API_DOMAIN, CONFIGS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function getConfig(): Promise<AppConfig> {
	const url = `${API_DOMAIN}${CONFIGS_URL}`;
	const response = await api.get<AppConfig>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	return response.data;
}

export async function createOrUpdatedConfig(config: AppConfig): Promise<AppConfig> {
	const url = `${API_DOMAIN}${CONFIGS_URL}`;
	const response = await api.post<AppConfig>(url, { config });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || config;
}

export async function createOrUpdatedConfigWithFiles(config: AppConfig, files?: File[]): Promise<AppConfig> {
	const url = `${API_DOMAIN}${CONFIGS_URL}/image`;
	const formData = new FormData();
	formData.append('config', JSON.stringify(config));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}
	const response = await api.post<AppConfig>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || config;
}
