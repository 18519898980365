import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AppConfigForm from 'components/AppConfigForm';
import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import { getConfig } from 'lib/models/configs';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { initialStateConfig } from 'config/constants';

function AppConfig() {
	const { t } = useTranslation();
	const [config, setConfig] = useState<AppConfig | null>(initialStateConfig);
	const [reload, setReload] = useState<boolean>(false);
	const errorParser = useErrorParser();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getConfig();
				setConfig(response);
			} catch (error) {
				const message = t('common:errorGetConfigs');
				enqueueSnackbarError(message, errorParser);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ScreenContainer
			title={t('common:config_plural')}
			goBack={true}
			sx={{
				padding: 3,
				mb: 3,
			}}
		>
			<PaperContainer sx={{ mb: 1 }}>
				<AppConfigForm setReload={setReload} reload={reload} config={config} setConfig={setConfig} />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default AppConfig;
