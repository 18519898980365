import { Dispatch, SetStateAction, useEffect } from 'react';
import { Container, Typography, FormGroup, /*FormControlLabel,*/ Grid, Divider, Checkbox } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { initialAvailability } from 'types/constants';

type Props = {
	availability?: OperatingHours | null;
	setDestination: Dispatch<SetStateAction<TravelDestination | null>>;
};

const AvailabilityForm = ({ availability, setDestination }: Props) => {
	const { t } = useTranslation();
	let now = dayjs();
	let formattedDate = now.format('YYYY-MM-DD');
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

	const handleCheckboxChange = (day: Days) => {
		setDestination((prevDestination) => {
			if (!prevDestination || !prevDestination.operatingHours) return;

			const newOperatingHours = {
				...prevDestination.operatingHours,
				[day]: { ...prevDestination.operatingHours[day], open: !prevDestination.operatingHours[day].open },
			};

			return { ...prevDestination, operatingHours: newOperatingHours };
		});
	};

	const handleTimePickerChange = (day: Days, timeType: 'startTime' | 'endTime', newValue: Dayjs | null) => {
		if (newValue) {
			const hours = newValue.format('hh');
			const minutes = newValue.format('mm');
			const period = newValue.format('A');

			setDestination((prevDestination) => {
				if (!prevDestination || !prevDestination.operatingHours) return;
				const newOperatingHours = {
					...prevDestination.operatingHours,
					[day]: {
						...prevDestination.operatingHours[day],
						[timeType]: { hours, minutes, period },
					},
				};

				return { ...prevDestination, operatingHours: newOperatingHours };
			});
		}
	};

	useEffect(() => {
		if (!availability) {
			setDestination((prevDestination) => {
				return { ...prevDestination, operatingHours: initialAvailability };
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Typography variant="body1">{t(`common:operatingHoursForm`)}</Typography>
			<Divider sx={{ width: '100%', my: 1, mt: 2 }} />

			<Container sx={{ mb: 2 }}>
				<FormGroup>
					{availability &&
						Object.keys(availability).map((day) => (
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Grid container key={day} spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h6">
											{t(`common:${day}`).charAt(0).toUpperCase() + t(`common:${day}`).slice(1)}
										</Typography>
									</Grid>
									<Grid item>
										<TimePicker
											label="Start Time"
											value={dayjs(
												`${formattedDate} ${availability[day as Days].startTime.hours}:${
													availability[day as Days].startTime.minutes
												} ${availability[day as Days].startTime.period}`,
												{ format: 'YYYY-MM-DD hh:mm A' }
											)}
											onChange={(newValue) => handleTimePickerChange(day as Days, 'startTime', newValue)}
											disabled={!availability[day as Days].open}
										/>
									</Grid>
									<Grid item>
										<TimePicker
											label="End Time"
											value={dayjs(
												`${formattedDate}  ${availability[day as Days].endTime.hours}:${
													availability[day as Days].endTime.minutes
												} ${availability[day as Days].endTime.period}`,
												{ format: 'YYYY-MM-DD hh:mm A' }
											)}
											onChange={(newValue) => handleTimePickerChange(day as Days, 'endTime', newValue)}
											disabled={!availability[day as Days].open}
										/>
									</Grid>
									<Grid item>
										<Checkbox
											{...label}
											checked={availability[day as Days].open}
											onChange={() => handleCheckboxChange(day as Days)}
										/>{' '}
										{t(`common:opened`)}
									</Grid>
								</Grid>
							</LocalizationProvider>
						))}
				</FormGroup>
			</Container>
		</>
	);
};

export default AvailabilityForm;
