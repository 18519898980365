import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
	reservation: Reservation;
	setLocationEdit?: Dispatch<SetStateAction<TravelDestination | null>>;
	setActiveTab?: Dispatch<SetStateAction<string>>;
};

function MobileItem({ reservation }: Props) {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="reservation-content" id="reservation-header">
						<Typography>{reservation.name}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
							onClick={
								() => navigate(`/reservations/${reservation._id}`) /*{
								if (setLocationEdit && setActiveTab) {
									setLocationEdit(reservation);
									setActiveTab('2');
								}
							}*/
							}
						>
							{t('common:email')}: {reservation.email}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:item')}: {reservation.itemId}
						</Box>

						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:status')}: {reservation.status}
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
