/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { enqueueSnackbarError, getStringFromData } from 'lib/helpers';

import useURLQuery from 'hooks/useURLQuery';

import useQuery, { Query, defaultQuery, isQuery } from 'hooks/useQuery';
import { reducer } from 'lib/queryReducer';

import FullLayout from 'components/layouts/FullLayout/FullLayout';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import SearchToolbar from 'components/SearchToolbar/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable/ResponsiveTable';
import MobileItem from './MobileItem';
import TableHead from '@mui/material/TableHead/TableHead';
import TableRow from '@mui/material/TableRow/TableRow';
import TableCell from '@mui/material/TableCell/TableCell';
import Box from '@mui/material/Box/Box';
import { rowsPerPageOptions } from 'config/constants';
import usePaginatedData from 'hooks/usePaginatedData';
import { fetchTags, updatedTag } from 'lib/models/tags';
import { Tooltip } from '@mui/material';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import TagForm from 'components/TagForm';

type Props = {
	reload?: boolean;
	setReload: Dispatch<SetStateAction<boolean>>;
};

function TagsTable({ reload, setReload }: Props) {
	const { t } = useTranslation();
	const [tagEdit, setTagsEdit] = useState<Tag | null>(null);
	const [searchParams, setSearchParams] = useURLQuery(isQuery, defaultQuery);

	const [{ order, page, rowsPerPage, search: searchValue }, dispatch] = useReducer(reducer, searchParams);

	const query = useQuery(searchValue, order, page, rowsPerPage);

	/**
	 * Checks if the search value was updated, if so it clears the mobile state
	 */
	const resetMobileState = useCallback((prev: Query | null) => !prev || prev.search !== searchValue, [searchValue]);

	const {
		loading,
		elements: tags,
		total,
		requestTotal,
		refetch,
	} = usePaginatedData(fetchTags, query, '_id', resetMobileState);

	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();

	const search = useCallback(
		(payload: string) => {
			dispatch({ type: 'SetSearch', payload });
		},
		[dispatch]
	);

	useEffect(() => {
		if (reload && setReload) {
			refetch();
			setReload(false);
		}
	}, [refetch, reload, setReload]);

	useEffect(() => {
		const queryString = `query=${getStringFromData(query)}`;
		setSearchParams(queryString);
	}, [query, setSearchParams]);

	async function editTags() {
		if (!tagEdit || !tagEdit._id) {
			return;
		}

		try {
			await updatedTag(tagEdit, tagEdit._id);
			setTagsEdit(null);
			refetch();
			successSnackbar();
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
	}

	function updateTagsKey(mainKey: keyof Tag, subKey?: keyof Price) {
		if (subKey === undefined) {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setTagsEdit({ ...(tagEdit || {}), [mainKey]: ev.target.value } as Tag);
			};
		} else {
			return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
				setTagsEdit((prevTags) => {
					if (!prevTags) {
						return null;
					}
					// If subKey is defined, it updates a nested property
					return Object.assign({}, prevTags, {
						[mainKey]: Object.assign({}, prevTags[mainKey], {
							[subKey]: ev.target.value,
						}),
					}) as Tag;
				});
			};
		}
	}

	if (false) {
		updateTagsKey('name');
		editTags();
	}

	return (
		<FullLayout>
			{loading && <LinearProgress />}

			<SearchToolbar onSearch={(value) => search(value)} live={false} initialValue={query.search} />

			<ResponsiveTable
				elements={tags}
				list={{
					primaryKey: '_id',
					infiniteScroll: {
						endOfList: requestTotal < rowsPerPage,
						onEndReached: () => dispatch({ type: 'SetPage', payload: page + 1 }),
					},
					renderListItemText: (tag) => <MobileItem tag={tag} setTagEdit={setTagsEdit} />,
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:description')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:show_in_screen')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (tag) => (
						<>
							<Tooltip title={t(`common:edit`)} arrow placement="top-start">
								<TableRow
									tabIndex={-1}
									key={tag._id}
									sx={{
										'&:hover': {
											backgroundColor: 'lightgray',
											cursor: 'pointer',
										},
									}}
									onClick={() => setTagsEdit(tag)}
								>
									<TableCell>{tag.name}</TableCell>
									<TableCell sx={{ mb: 1 }}>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{tag.description && tag.description.length > 45
												? `${tag.description.slice(0, 47)}...`
												: tag.description}
										</Box>
									</TableCell>
									<TableCell>
										<Box
											sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}
										>
											{tag.show ? t(`common:yes`) : t(`common:not`)}
										</Box>
									</TableCell>
								</TableRow>
							</Tooltip>
						</>
					),
					pagination: {
						count: total,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: (_, payload) => dispatch({ type: 'SetPage', payload }),
						onRowsPerPageChange: ({ target }) => {
							return dispatch({ type: 'SetRowsPerPage', payload: parseInt(target.value, 10) });
						},
					},
				}}
			/>

			<TagForm setReload={setReload} reload={reload} tag={tagEdit} setTag={setTagsEdit} type="edit" />
		</FullLayout>
	);
}

export default TagsTable;
