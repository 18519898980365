import { Route, BrowserRouter, Routes } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';

import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';

import Auth from './Auth';

import useLogoutOnTokenExpired from './hooks/useLogoutOnTokenExpired';
import Logged from './Logged';
import Locations from 'components/screens/Locations/Locations';
import Accommodations from 'components/screens/Accommodations/Accommodations';
import Activities from 'components/screens/Activities/Activities';
import Tags from 'components/screens/Tags/Tags';
import LocationEdition from 'components/screens/LocationEdition';
import AppConfig from 'components/screens/AppConfig';
import AboutUs from 'components/screens/AboutUs/AboutUs';
import CustomizedHome from 'components/screens/CustomizedHome';
import Reservations from 'components/screens/Reservations';
import ReservationEdition from 'components/screens/ReservationEdition';

function Router() {
	// If token has expired, logout user
	useLogoutOnTokenExpired();

	return (
		<BrowserRouter>
			<Routes>
				<Route key="auth" element={<Logged />}>
					{/* PRIVATE ROUTES */}
					<Route key="route-me" path="/me" element={<Auth element={UsersEdition} rules={['Profile']} />} />
					<Route key="route-users-edit" path="/users/:id" element={<Auth element={UsersEdition} rule="UserFull" />} />
					<Route key="route-users" path="/users" element={<Auth element={Users} rule="UserFull" />} />
					{/* SYSTEM PRIVATE ROUTES */}
					<Route key="route-inbox" path="/inbox" element={<Auth element={Inbox} />} />
					<Route key="route-locations" path="/locations" element={<Auth element={Locations} />} />
					<Route key="route-locations-edit" path="/locations/:id" element={<Auth element={LocationEdition} />} />
					<Route
						key="route-reservations-edit"
						path="/reservations/:id"
						element={<Auth element={ReservationEdition} />}
					/>
					<Route key="route-activities" path="/activities" element={<Auth element={Activities} />} />
					<Route key="route-accommodations" path="/accommodations" element={<Auth element={Accommodations} />} />
					<Route key="route-tags" path="/tags" element={<Auth element={Tags} />} />
					<Route key="route-configs" path="/configs" element={<Auth element={AppConfig} />} />
					<Route key="route-about-us" path="/aboutUs" element={<Auth element={AboutUs} />} />
					<Route key="route-customized-home" path="/customizedHome" element={<Auth element={CustomizedHome} />} />
					<Route key="route-home" path="/" element={<Auth element={Dashboard} />} />
					<Route key="route-reservations" path="/reservations" element={<Auth element={Reservations} />} />
				</Route>
				{/* SYSTEM PUBLIC ROUTES */}
				<Route key="route-login" path="/login" element={<Login />} />
				{/* NoMatch */}
				<Route element={<NoMatch />} />
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
