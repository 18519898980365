import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useErrorParser() {
	const { t } = useTranslation();

	const parser = useCallback(
		/**
		 * An error parser method that will receive a string and return a custom label if the string "the identifier received" is present
		 */
		(message: string) => {
			return message.includes('the identifier received ') ? t('clients:identificationErrorMessage') : message;
		},
		[t]
	);

	return parser;
}
