import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';

type Props = {
	accommodation: Accommodation;
	setAccommodationEdit: Dispatch<SetStateAction<Accommodation | null>>;
};

function MobileItem({ accommodation, setAccommodationEdit }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="accommodation-content"
						id="accommodation-header"
					>
						<Typography>{accommodation.name}</Typography>
					</AccordionSummary>
					<AccordionDetails onClick={() => setAccommodationEdit(accommodation)}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
						>
							{t('common:description')}: {accommodation.description}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:including')}:{' '}
							{accommodation.including?.map((item, i) => (
								<div key={i}>{item}, </div>
							))}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:not_including')}:{' '}
							{accommodation.not_including?.map((item, i) => (
								<div key={i}>{item}, </div>
							))}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:price')}: {accommodation.price?.currency} {accommodation.price?.value}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:persons')}: {accommodation.price?.persons}
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
