import { useTranslation } from 'react-i18next';

import ScreenContainer from 'components/containers/ScreenContainer';

//import { useState } from 'react';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import LocationsTable from 'components/LocationsTable/LocationsTable';
//import TravelDestinationForm from 'components/TravelDestinationForm';
import CreateIcon from 'components/icons/CreateIcon';
import { useNavigate } from 'react-router-dom';

function Locations() {
	const { t } = useTranslation();

	const navigate = useNavigate();
	/*const [activeTab, setActiveTab] = useState('1');
	const [locationEdit, setLocationEdit] = useState<TravelDestination | null>(null);

	const handleChange = (event: React.SyntheticEvent, newActiveTab: string) => {
		setActiveTab(newActiveTab);
		if (newActiveTab === '1' || newActiveTab === '3') {
			setLocationEdit(null);
		}
	};*/

	return (
		<ScreenContainer title={t('common:location_plural')} goBack={true}>
			<CreateIcon onCreate={() => navigate('/locations/new')} />

			<Box sx={{ mb: 2 }}>
				<LocationsTable />
			</Box>
		</ScreenContainer>
	);
}

export default Locations;
