import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';

import { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { fetchContact } from 'lib/models/contacts';
import ReservationForm from 'components/ReservationForm';

function LocationEdition() {
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [reservation, setReservation] = useState<Reservation | null>(null);
	const [reload, setReload] = useState<boolean>(false);

	const { id } = useParams<{ id: string }>();

	const newUser = !!id && id === 'new';

	const title = loading
		? t('common:loading')
		: newUser
		? `${t('common:create')} ${t('common:location')}`
		: `${t('common:edit')} ${t('common:location')}`;

	const getContact = async (_id: string) => {
		try {
			setLoading(true);
			const results = await fetchContact(_id);
			setReservation(results);
			setLoading(false);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		if (id && id !== 'new') {
			getContact(id);
		}
	}, [id]);

	useEffect(() => {
		if (reload) {
			if (id && id !== 'new') {
				getContact(id);
			}

			setReload(false);
		}
	}, [id, reload]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer sx={{ mb: 1 }}>
				{id &&
					(loading ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
							<CircularProgress disableShrink />
						</Box>
					) : (
						<ReservationForm reservation={reservation} setReservation={setReservation} />
					))}
			</PaperContainer>
		</ScreenContainer>
	);
}

export default LocationEdition;
