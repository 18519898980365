import { useTranslation } from 'react-i18next';
import FluidContainer from 'components/containers/FluidContainer';
import AccommodationsTable from 'components/AccommodationsTable/AccommodationsTable';
import CreateIcon from 'components/icons/CreateIcon/CreateIcon';
import { useState } from 'react';

import AccommodationForm from 'components/AccommodationForm';

function Accommodations() {
	const { t } = useTranslation();
	const [accommodation, setAccommodation] = useState<Accommodation | null>(null);
	const [reload, setReload] = useState<boolean>(false);

	return (
		<FluidContainer title={t('common:accommodation_plural')} goBack={true}>
			<CreateIcon
				onCreate={() =>
					setAccommodation({
						name: '',
						description: '',
						including: [''],
						not_including: [''],
						price: {
							value: 0,
							currency: 'USD',
							persons: 1,
						},
					})
				}
			/>
			<AccommodationsTable reload={reload} setReload={setReload} />
			<AccommodationForm
				setReload={setReload}
				reload={reload}
				accommodation={accommodation}
				setAccommodation={setAccommodation}
				type="create"
			/>
		</FluidContainer>
	);
}

export default Accommodations;
