import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function Accommodations({ selected, onSelect }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/accommodations"
			selected={selected === MenuItem.Accommodation}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Accommodation)}
		>
			<ListItemIcon>
				<HomeIcon />
			</ListItemIcon>
			<ListItemText primary={t('common:accommodation_plural')} />
		</ListItem>
	);
}
