import { API_DOMAIN, CUSTOMIZED_HOME_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function getCustomizedHome(): Promise<CustomizedHome> {
	const url = `${API_DOMAIN}${CUSTOMIZED_HOME_URL}`;
	const response = await api.get<CustomizedHome>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	return response.data;
}

export async function createOrUpdatedCustomizedHome(customizedHome: CustomizedHome): Promise<CustomizedHome> {
	const url = `${API_DOMAIN}${CUSTOMIZED_HOME_URL}`;
	const response = await api.post<CustomizedHome>(url, { customizedHome });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || customizedHome;
}

export async function createOrUpdatedCustomizedHomeWithFiles(
	customizedHome: CustomizedHome,
	file?: File
): Promise<CustomizedHome> {
	const url = `${API_DOMAIN}${CUSTOMIZED_HOME_URL}/image`;
	const formData = new FormData();
	formData.append('customizedHome', JSON.stringify(customizedHome));

	if (file) {
		formData.append('file', file);
	}

	const response = await api.post<CustomizedHome>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || customizedHome;
}
