import { API_DOMAIN, GENERICS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export type FetchGenericCombinedSearchProps = {
	search?: string | null;
};

export type FetchGenericsProps = FetchGenericCombinedSearchProps & PaginationProps;

export async function fetchGenericCombined(): Promise<GenericCombined> {
	try {
		const response = await api.get<GenericCombined>(`${API_DOMAIN}${GENERICS_URL}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { tags: [], accommodations: [], activities: [] };
	}
}

export async function fetchFiles(fileNames: string[]): Promise<Array<{ file: string; type: string }> | null> {
	try {
		const response = await api.get<Array<{ file: string; type: string }>>(
			`${API_DOMAIN}${GENERICS_URL}/files/multiple?files=${fileNames.join('&files=')}`
		);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (response.status === 200) {
			return response.data;
		} else {
			console.error('Error getting the file:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error getting the file:', error);
		return null;
	}
}
