import { Box, Chip, Grid, Stack } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import StyleIcon from '@mui/icons-material/Style';
import HomeIcon from '@mui/icons-material/Home';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import ActivityField from './ActivityField';
import AccommodationField from './AccommodationField';
import TagField from './TagField';
type Props = {
	destination: TravelDestination | null;
	setDestination: Dispatch<SetStateAction<TravelDestination | null>>;
	updateDestinationKey: (mainKey: keyof TravelDestination, subKey?: keyof ItemLocation | keyof Contact) => any;
	resultsCombined: GenericCombined | null;
	reload: boolean;
	setReload: Dispatch<SetStateAction<boolean>>;
};
const DestinationChips = ({
	destination,
	setDestination,
	updateDestinationKey,
	resultsCombined,
	reload,
	setReload,
}: Props) => {
	const handleDeleteActivity = (indexToDelete: number) => {
		setDestination((prevDestination) => {
			if (!prevDestination || !prevDestination.activity) {
				return prevDestination;
			}

			const updatedActivities = [...prevDestination.activity];
			updatedActivities.splice(indexToDelete, 1);

			return {
				...prevDestination,
				activity: updatedActivities,
			};
		});
	};

	return (
		<>
			<ActivityField
				destination={destination}
				updateDestinationKey={updateDestinationKey}
				resultsCombined={resultsCombined}
				reload={reload}
				setReload={setReload}
				setDestination={setDestination}
			/>
			<AccommodationField
				destination={destination}
				updateDestinationKey={updateDestinationKey}
				resultsCombined={resultsCombined}
				reload={reload}
				setReload={setReload}
				setDestination={setDestination}
			/>
			<TagField
				destination={destination}
				updateDestinationKey={updateDestinationKey}
				resultsCombined={resultsCombined}
				reload={reload}
				setReload={setReload}
				setDestination={setDestination}
			/>
			<Grid item xs={12}>
				<Box
					sx={{
						borderWidth: 1,
						borderRadius: 3,
						minHeight: 50,
						maxHeight: 400,
						margin: 'auto',
						mb: 2,
						py: 2,
						px: 2,
						backgroundColor: '#f9f9f9',
						borderColor: '#d3d3d3',
						borderStyle: 'solid',
						overflowY: 'auto',
						maxWidth: '100%',
						overflowX: 'auto',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Stack direction="row" sx={{ flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center' }}>
						{destination?.accommodation && destination.accommodation._id !== '' && (
							<Chip
								icon={<HomeIcon />}
								label={destination?.accommodation?.name}
								onDelete={() =>
									setDestination({
										...destination,
										accommodation: {
											name: '',
											_id: '',
											including: [''],
											not_including: [''],
										},
									})
								}
								sx={{ mr: 2, mb: 1 }}
							/>
						)}

						{destination?.tag && destination.tag._id !== '' && (
							<Chip
								icon={<StyleIcon />}
								label={destination?.tag?.name}
								onDelete={() =>
									setDestination({
										...destination,
										tag: {
											name: '',
											_id: '',
										},
									})
								}
								sx={{ mr: 2, mb: 1 }}
							/>
						)}

						{(destination?.activity?.length ?? 0) > 0 &&
							(destination?.activity ?? []).map(
								(activity, index) =>
									activity &&
									activity.name !== '' &&
									activity._id !== '' && (
										<Chip
											key={index}
											icon={<LocalFireDepartmentIcon />}
											onDelete={() => handleDeleteActivity(index)}
											label={activity?.name}
											sx={{ mr: 2, mb: 1 }}
										/>
									)
							)}
					</Stack>
				</Box>
			</Grid>
		</>
	);
};

export default DestinationChips;
