import { Divider, Grid, Typography } from '@mui/material';
import BoxImagesWithPreview from 'components/BoxImagesWithPreview';
import BoxImagesWithoutPreview from 'components/BoxImagesWithoutPreview';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
type Props = PropsWithChildren<{}> & {
	selectedImages: File[];
	showImages: string[] | null;
	handleImageRemove: (index: number) => void;
	setSelectedImages: Dispatch<SetStateAction<File[]>>;
	type: 'create' | 'edit';
};
const FilesAboutUs = ({ selectedImages, handleImageRemove, setSelectedImages, showImages, type, children }: Props) => {
	const { t } = useTranslation();
	return (
		<>
			<Grid item xs={12}>
				<Typography variant="body1">{t(`common:file_plural`)}</Typography>
				<Divider sx={{ width: '100%', my: 1 }} />
			</Grid>
			{children}

			{type === 'edit' && showImages && showImages.length > 0 && (
				<BoxImagesWithPreview images={showImages} handleImageRemove={handleImageRemove} />
			)}
			<BoxImagesWithoutPreview
				images={selectedImages}
				handleImageRemove={handleImageRemove}
				setSelectedImages={setSelectedImages}
			/>
		</>
	);
};

export default FilesAboutUs;
