import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function Tags({ selected, onSelect }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/reservations"
			selected={selected === MenuItem.Reservations}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Reservations)}
		>
			<ListItemIcon>
				<ContactMailIcon />
			</ListItemIcon>
			<ListItemText primary={t('common:reservations')} />
		</ListItem>
	);
}
