import { API_DOMAIN, TAGS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchTagSearchProps = {
	search?: string | null;
};

export type FetchTagsProps = FetchTagSearchProps & PaginationProps;

export async function fetchTags(props: FetchTagsProps): Promise<PaginateSource<Tag>> {
	try {
		const sort: Sort = {
			[props.orderBy || 'name' || 'description']: props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Tag>>(`${API_DOMAIN}${TAGS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}
export async function createTag(tag: Tag): Promise<Tag> {
	const url = `${API_DOMAIN}${TAGS_URL}`;
	const response = await api.post<Tag>(url, { tag });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || tag;
}

export async function createTagWithFile(tag: Tag, file?: File): Promise<Tag> {
	const url = `${API_DOMAIN}${TAGS_URL}/image`;

	const formData = new FormData();
	formData.append('tag', JSON.stringify(tag));

	if (file) {
		formData.append('file', file);
	}

	const response = await api.post<Tag>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || tag;
}

export async function fetchFile(fileName: string): Promise<{ file: string; type: string } | null> {
	try {
		const response = await api.get<{ file: string; type: string }>(`${API_DOMAIN}${TAGS_URL}/files/${fileName}`);
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		if (response.status === 200) {
			return response.data;
		} else {
			console.error('Error getting the file:', response.status);
			return null;
		}
	} catch (error) {
		console.error('Error getting the file:', error);
		return null;
	}
}

export async function updatedTag(tag: Tag, _id: string) {
	const url = `${API_DOMAIN}${TAGS_URL}/${_id}`;
	const response = await api.patch(url, { tag });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function updatedTagWithFile(tagId: string, tag: Tag, file?: File): Promise<Tag> {
	const url = `${API_DOMAIN}${TAGS_URL}/${tagId}/image`;

	const formData = new FormData();
	formData.append('tag', JSON.stringify(tag));

	if (file) {
		formData.append('file', file);
	}

	const response = await api.patch<Tag>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || tag;
}
