import { Close } from '@mui/icons-material';
import { Box, Button, Modal, Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
	open: boolean;
	children?: ReactNode;
	onClose: () => void;
	title?: string;
	description?: string;
};

const AddItem = ({ open, children, onClose, title, description }: Props) => {
	return (
		<Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: '90%',
					bgcolor: 'background.paper',
					border: '2px solid lightgrey',
					boxShadow: 24,
					p: 4,
					overflowY: 'auto',
					maxHeight: '90vh',
					display: 'block',
				}}
			>
				<Button onClick={onClose} variant="outlined" color="error" sx={{ position: 'absolute', top: 2, right: 2 }}>
					<Close />
				</Button>

				<Box sx={{ mt: 2 }}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						{title}
					</Typography>

					<Typography id="modal-modal-description" variant="body1" component="p">
						{description}
					</Typography>

					<Box sx={{ mt: 2 }}>{children}</Box>
				</Box>
			</Box>
		</Modal>
	);
};

export default AddItem;
