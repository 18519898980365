import { API_DOMAIN, DESTINATIONS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchDestinationSearchProps = {
	search?: string | null;
};

export type FetchDestinationsProps = FetchDestinationSearchProps & PaginationProps;

export async function fetchDestinations(props: FetchDestinationsProps): Promise<PaginateSource<TravelDestination>> {
	try {
		const sort: Sort = {
			[props.orderBy || '_id' || 'name' || 'description' || 'province' || 'address' || 'date']:
				props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<TravelDestination>>(`${API_DOMAIN}${DESTINATIONS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function fetchDestination(_id: string): Promise<TravelDestination | null> {
	try {
		const response = await api.get<TravelDestination | null>(`${API_DOMAIN}${DESTINATIONS_URL}/${_id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function createDestination(travelDestination: TravelDestination): Promise<TravelDestination> {
	const url = `${API_DOMAIN}${DESTINATIONS_URL}`;
	const response = await api.post<TravelDestination>(url, { travelDestination });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || travelDestination;
}

export async function createDestinationWithFiles(
	destination: TravelDestination,
	files?: File[]
): Promise<TravelDestination> {
	const url = `${API_DOMAIN}${DESTINATIONS_URL}/image`;

	const formData = new FormData();
	formData.append('travelDestination', JSON.stringify(destination));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}

	const response = await api.post<TravelDestination>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || destination;
}

export async function updatedDestination(travelDestination: TravelDestination, _id: string) {
	const url = `${API_DOMAIN}${DESTINATIONS_URL}/${_id}`;
	const response = await api.patch(url, { travelDestination });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function updatedDestinationWithFiles(
	destinationId: string,
	destination: TravelDestination,
	files?: File[]
): Promise<TravelDestination> {
	const url = `${API_DOMAIN}${DESTINATIONS_URL}/${destinationId}/image`;

	const formData = new FormData();
	formData.append('travelDestination', JSON.stringify(destination));

	if (files && files.length > 0) {
		files.forEach((file, index) => {
			formData.append(`files`, file);
		});
	}

	const response = await api.patch<TravelDestination>(url, formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});

	if (!checkStatus(response)) {
		throw new Error('Invalid credentials');
	}

	return response.data || destination;
}

export async function deleteDestination(_id: string) {
	const url = `${API_DOMAIN}${DESTINATIONS_URL}/${_id}`;
	const response = await api.delete(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}
