const common = {
	// Common
	name: 'Name',
	username: 'Username',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	email: 'Email',
	accept: 'Accept',
	cancel: 'Cancel',
	close: 'Close',
	create: 'Create',
	delete: 'Delete',
	edit: 'Edit',
	duplicate: 'Duplicate',
	filter: 'Filter',
	add: 'Add',
	general: 'General',
	go: 'Go',
	open: 'Open',
	opened: 'Opened',
	reload: 'Reload',
	save: 'Save',
	search: 'Search',
	watch: 'Watch',
	selected: 'selected',
	upload: 'Upload',
	view: 'View',
	success: 'Saved',
	confirmSuccess: 'Was saved correctly, please confirm by pressing the save button',
	download: 'Download',
	densePadding: 'Dense row',
	rowsPerPage: 'Rows per page',
	findUser: 'Find user ...',
	import: 'Import',
	importingPleaseWait: 'Please wait, importing...',
	more: 'More',
	code: 'Code',
	loading: 'Loading, please wait...',
	role: 'Role',
	role_admin: 'Administrator',
	role_panel: 'Panel user',
	role_user: 'Client',
	active: 'Active',
	inactive: 'Inactive',
	selectFile: 'Select file',
	fileError: 'File format not supported',
	importSuccess: 'File imported successfully',
	language: 'language',
	spanish: 'Spanish',
	english: 'English',
	valueTo: 'Up to',
	$lte: 'Less than or equal to',
	$gte: 'Greater than or equal to',
	backOfficeDocumentationTitle: 'Panel documentation',
	backOfficeDocumentationDescription: 'Panel usage documentation link',
	itDocumentationTitle: 'IT documentation',
	itDocumentationDescription: 'Server configuration documentation link',
	welcome:
		'Welcome {{name}}, if you need help with the panel or some server configuration you can use the links below.',
	welcomeClient: 'Welcome {{name}}',
	export: 'Export',
	tokenExpired: 'Your session has expired, please login again',
	serverIsDown: 'The API server is down, please contact IT team for more info',
	followingElementsWithError:
		'The following elements with column "{{key}}" ({{elements}}) could not be created or updated, error: {{error}}',
	status: 'Status',
	deleteTitle: 'Deletion',
	deleteText: 'Are you sure you want to delete the selected element?',
	cancelDescription: "Are you sure you wan't to return to login screen?",
	exampleFile: 'Download an example .csv file',
	dashboard: 'dashboard',
	favorite: 'favorite',
	favorite_plural: 'favorites',
	inbox: 'inbox',
	personal_area: 'My section',
	profile: 'profile',
	user: 'user',
	user_plural: 'users',
	user_me: 'my data',
	userTitle: '{{name}}',
	resetPassword: 'Clear password',
	resetPasswordTitle: 'Clear password',
	resetPasswordText: 'Clear password of selected user?',
	creationPasswordText:
		'The first time the user logs in it will create his password which will be used in subsequent accesses.',
	loginDate: 'Last login',
	loadError: 'There was a problem trying to load the data',
	identifier: 'Identifier',
	companyName: 'Company name',
	company: 'Company',
	city: 'City',
	address: 'Address',
	phone: 'Phone',
	phoneNumer: 'Phone Number',
	alternativeEmail: 'Alternartive Email',
	reservations: 'Reservations',
	associationLabel: 'Association Label',
	actions: 'Actions',
	theseAreAllEmails: 'List of email addresses',
	theseAreAllPhoneNumbers: 'List of telephone numbers',
	data: 'Data',
	contact: 'Contact',
	mode: 'Mode',
	date: 'Date',
	operatingHoursForm: 'Hours of operation',
	range: 'Range',
	from: 'From',
	to: 'To',
	to2: 'to',
	website: 'Website',
	users: 'Users',
	crm: 'CRM',
	bill: 'bill',
	bills: 'bills',
	person: 'person',
	person_plural: 'people',
	document: 'document',
	document_plural: 'documents',
	location: 'location',
	province: 'Province',
	meeting_point: 'Meeting point',
	mobilePhone: 'Mobile phone',
	url_video: 'Video embedded url',
	url_maps: 'Google maps embedded url',
	url_instagram: 'Instagram embedded url',
	location_plural: 'locations',
	file: 'File',
	file_plural: 'Files',
	links: 'Links',
	activity: 'Activity',
	activity_plural: 'Activities',
	config: 'Config',
	config_plural: 'Configuraciones',
	accommodation: 'Accomodation',
	accommodation_plural: 'Accomodations',
	tag_plural: 'Tags',
	tags: 'Tags',
	or: 'or',
	tag: 'Tag',
	price: 'Price',
	price_plural: 'Prices',
	including: 'Including',
	including_plural: 'Including',
	not_including: 'Not including',
	not_including_plural: 'Not Including',
	upload_file: 'Upload file',
	upload_image: 'Upload image',
	replace_image: 'Replace image',
	show_in_screen: 'Show Tag in Screen',
	yes: 'Yes',
	not: 'Not',
	currency: 'currency',
	persons: 'Persons',
	main: 'main',
	documentation: 'documentation',
	dates: 'dates',
	contacts: 'contacts',
	remove: 'remove',
	total: 'Total',
	action: 'Action',
	dateSendEmail: 'Date e-mail sent',
	changeDay: 'Change day',
	assingDay: 'Assign day',
	page_customizers: 'Page customizers',
	customized: 'customized',
	quotation_section: 'Quotation section',
	about_us: 'About Us',
	customized_home: 'Home',
	title: 'Title',
	text: 'Text',
	form: 'Form',
	embedded_pdf: 'Pdf embedded url',
	button: 'Button',
	maturitiesOfTheDay: 'Maturities of the day',
	transfer: 'Transfer',
	cash: 'Cash',
	draft: 'Draft',
	pending: 'Sent',
	accepted: 'Accepted',
	rejected: 'Rejected',
	deleted: 'Deleted',
	copy: 'Copy',
	copied: 'Copied',
	copy_all: 'Copy all',
	contact_plural: 'Contacts',
	capital: 'Capital',
	capitalValue: 'CAPITAL: {{value}}',
	capitalInterestValue: 'INTEREST: {{value}}',
	other: 'Others',
	additionalData: 'Additional data',
	replace: 'Replace',
	contractSent: 'Contract sent',
	installmentCount: 'Installment count',
	interest: 'Interest',
	monthlyInterest: 'Monthly interest',
	acceptanceStatus: 'Acceptance status',
	installments: 'installments',
	description: 'Description',
	dueDate: 'Due date',
	nextDueDates: 'Next due dates',
	month: 'Month',
	month_0: 'January',
	month_1: 'February',
	month_2: 'March',
	month_3: 'April',
	month_4: 'May',
	month_5: 'June',
	month_6: 'July',
	month_7: 'August',
	month_8: 'September',
	month_9: 'October',
	month_10: 'November',
	month_11: 'December',
	monday: 'Monday',
	tuesday: 'Tuesday',
	wednesday: 'Wednesday',
	thursday: 'Thursday',
	friday: 'Friday',
	saturday: 'Saturday',
	sunday: 'Sunday',
	sendContract: 'Send contract',
	resendContract: 'Resend contract',
	unpaid: 'unpaid',
	paid: 'paid',
	selectOpeningDate: 'Select opening date',
	openingDate: 'Opening date',
	nextDate: 'Next date',
	nextDueDate: 'Next due date',
	documentTitle: 'Document',
	documentType: 'Type',
	invoiceNumber: 'Invoice number',
	receipt: 'Receipt',
	documentDate: 'Date',
	todayDueDates: "Today's Due Dates notifications",
	lastNotification: 'Last notification',
	monthlyDueDates: 'Accumulated month-end deadlines',
	withoutNotifications: 'There are no pending notifications for today',
	privacy: 'Privacy',
	conditions: 'Conditions',
	errorGetConfigs: 'No configurations were found or there was an error trying to get them',
	reserveStatus: 'Reserve Status',
	message: 'Message',
	reservationDetails: 'Reservation Details',
	reservationDate: 'Reservation Date',
	event: 'Event',
	locationId: 'Item Id',
	pendingStatus: 'Pending',
	approved: 'Approved',
} as const;

export default common;
