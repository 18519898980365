import { API_DOMAIN, CONTACTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus, getSearch, isPaginateSource } from 'lib/helpers';

export type FetchContactSearchProps = {
	search?: string | null;
};

export type FetchContactsProps = FetchContactSearchProps & PaginationProps;
export async function fetchContacts(props: FetchContactsProps): Promise<PaginateSource<Reservation>> {
	try {
		const sort: Sort = {
			[props.orderBy || '_id' || 'name' || 'email' || 'itemId' || 'status']:
				props.order && props.order === 'asc' ? 1 : -1,
		};
		const { filter, options } = getSearch(sort, props);

		const response = await api.get<PaginateSource<Reservation>>(`${API_DOMAIN}${CONTACTS_URL}`, {
			params: { filter, options },
		});
		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}
		if (!isPaginateSource(response.data)) {
			throw new Error('invalid response');
		}
		return response.data;
	} catch (error) {
		console.error(error);
		return { elements: [], total: 0 };
	}
}

export async function updatedContact(reservation: Reservation, _id: string) {
	const url = `${API_DOMAIN}${CONTACTS_URL}/${_id}`;
	const response = await api.patch(url, { reservation });

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export async function fetchContact(_id: string): Promise<Reservation | null> {
	try {
		const response = await api.get<Reservation | null>(`${API_DOMAIN}${CONTACTS_URL}/${_id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}
