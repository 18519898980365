export enum UsersTypes {
	admin = 'admin',
	panel = 'panel',
	user = 'user',
}

export enum MenuItem {
	Dashboard = 0,
	Inbox = 1,
	Starred = 2,
	Users = 3,
	User = 4,
	Documents = 5,
	Documentation = 6,
	Locations = 7,
	Accommodation = 8,
	Activity = 9,
	Tag = 10,
	AppConfig = 11,
	AboutUs = 12,
	CustomizedHome = 13,
	Reservations = 14,
}
