import { useTranslation } from 'react-i18next';
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { createOrUpdatedAboutUs, createOrUpdatedAboutUsWithFiles } from 'lib/models/aboutUs';
import useSuccessSnackbar from 'hooks/useSuccessSnakbar';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { initialStateAboutUs } from 'config/constants';
import FilesAboutUs from './FilesAboutUs';
import { fetchFiles } from 'lib/models/generics';

type Props = {
	setReload?: Dispatch<SetStateAction<boolean>>;
	reload?: boolean;
	config: AboutUs | null;
	setConfig: Dispatch<SetStateAction<AboutUs | null>>;
	//setAssignConfig?: (item?: AboutUs) => void;
};

function AboutUsForm({ setReload, reload, config, setConfig }: Props) {
	const type = 'create';
	const [selectedImages, setSelectedImages] = useState<File[]>([]);
	const [showImages, setShowImages] = useState<string[] | null>(null);
	const { t } = useTranslation();
	const successSnackbar = useSuccessSnackbar();
	const errorParser = useErrorParser();
	const saveOrEdit = t(type === 'create' ? `common:save` : `common:edit`);

	function updateConfigKey(mainKey: keyof AboutUs) {
		return (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setConfig((prevConfig) => {
				if (!prevConfig) {
					return initialStateAboutUs;
				}

				return { ...prevConfig, [mainKey]: ev.target.value } as AboutUs;
			});
		};
	}

	async function uploadConfig(config: AboutUs | null) {
		if (!config) {
			return;
		}

		try {
			if (setReload) {
				setReload(true);
			}
			await addOrUpdateConfig(config);
			successSnackbar();
			setSelectedImages([]);
		} catch (error) {
			console.warn(error);
			enqueueSnackbarError(error, errorParser);
		}
		setReload && setReload(false);
	}

	async function addOrUpdateConfig(config: AboutUs): Promise<AboutUs> {
		if (!selectedImages || selectedImages.length < 1) {
			return await createOrUpdatedAboutUs(config);
		} else {
			const result = await createOrUpdatedAboutUsWithFiles(config, selectedImages);
			if (result && result !== null) setConfig(result);
			return result;
		}
		//return await createOrUpdatedConfig(config);
	}

	const handleImageRemove = (index: number, edit?: string) => {
		if (edit && config?.images) {
			const updatedImages = [...(config?.images || [])];
			const updatedViewImages = [...(showImages || [])];
			updatedImages.splice(index, 1);
			updatedViewImages.splice(index, 1);
			setConfig((prevDestination: AboutUs | null) => {
				if (!prevDestination) {
					return null;
				}
				return {
					...prevDestination,
					images: updatedImages,
				};
			});
			setShowImages(updatedViewImages);
		} else {
			const updatedImages = [...selectedImages];
			updatedImages.splice(index, 1);
			setSelectedImages(updatedImages);
		}
	};

	const loadImages = async (files: string[] | null) => {
		try {
			if (files === null) {
				return;
			}
			const fileResponse = await fetchFiles(files);
			if (fileResponse) {
				const images = fileResponse.map((fileData) => `data:image/${fileData.type};base64,${fileData.file}`);
				setShowImages(images);
			}
		} catch (error) {
			console.warn(error);
		}
	};

	useEffect(() => {
		if (config?.images && (config?.images.length > 1 || (config?.images[0] !== '' && config?.images.length > 0))) {
			loadImages(config.images);
		}
	}, [config?.images]);

	return (
		<Box
			sx={{
				flexGrow: 1,
				padding: 3,
				overflowY: 'auto',
			}}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12}>
					<TextField
						id="title1"
						label={`${t('common:title')} 1`}
						value={config?.title1}
						fullWidth={true}
						onChange={updateConfigKey('title1')}
						variant="outlined"
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`title2`}
						label={`${t('common:title')} 2`}
						fullWidth
						value={config?.title2}
						onChange={updateConfigKey('title2')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`text`}
						label={`${t('common:text')}`}
						fullWidth
						value={config?.text}
						onChange={updateConfigKey('text')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`button`}
						label={`${t('common:button')}`}
						fullWidth
						value={config?.button}
						onChange={updateConfigKey('button')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`titleForm`}
						label={`${t('common:title')} ${t('common:form')}`}
						fullWidth
						value={config?.titleForm}
						onChange={updateConfigKey('titleForm')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						id={`textForm`}
						label={`${t('common:text')} ${t('common:form')}`}
						fullWidth
						value={config?.textForm}
						onChange={updateConfigKey('textForm')}
						sx={{ backgroundColor: '#fff' }}
					/>
				</Grid>
				<FilesAboutUs
					selectedImages={selectedImages}
					showImages={showImages}
					handleImageRemove={handleImageRemove}
					setSelectedImages={setSelectedImages}
					type={'edit'}
				>
					<Grid item xs={12} sm={12}>
						<TextField
							id={`urlPdf`}
							label={`${t('common:embedded_pdf')}`}
							fullWidth
							placeholder="https://www..."
							value={config?.urlPdf}
							onChange={updateConfigKey('urlPdf')}
							sx={{ backgroundColor: '#fff' }}
						/>
					</Grid>
				</FilesAboutUs>
				<Grid item xs={12}>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
						<Button onClick={() => uploadConfig(config)} variant="outlined" disabled={reload}>
							{saveOrEdit}
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
}

export default AboutUsForm;
