import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import { useErrorParser } from 'hooks/useErrorParser';
import { enqueueSnackbarError } from 'lib/helpers';
import { initialStateAboutUs } from 'config/constants';
import AboutUsForm from 'components/AboutUsForm';
import { getAboutUs } from 'lib/models/aboutUs';

function AboutUs() {
	const { t } = useTranslation();
	const [config, setConfig] = useState<AboutUs | null>(initialStateAboutUs);
	const [reload, setReload] = useState<boolean>(false);
	const errorParser = useErrorParser();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await getAboutUs();
				setConfig(response);
			} catch (error) {
				const message = t('common:errorGetConfigs');
				enqueueSnackbarError(message, errorParser);
			}
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ScreenContainer
			title={t('common:config_plural')}
			goBack={true}
			sx={{
				padding: 3,
				mb: 3,
			}}
		>
			<PaperContainer sx={{ mb: 1 }}>
				<AboutUsForm setReload={setReload} reload={reload} config={config} setConfig={setConfig} />
			</PaperContainer>
		</ScreenContainer>
	);
}

export default AboutUs;
