import { useTranslation } from 'react-i18next';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Dispatch, SetStateAction } from 'react';

type Props = {
	activity: Activity;
	setActivityEdit: Dispatch<SetStateAction<Activity | null>>;
};

function MobileItem({ activity, setActivityEdit }: Props) {
	const { t } = useTranslation();

	return (
		<Accordion defaultExpanded={false} variant="outlined">
			{
				<>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="activity-content" id="activity-header">
						<Typography>{activity.name}</Typography>
					</AccordionSummary>
					<AccordionDetails onClick={() => setActivityEdit(activity)}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 8,
								justifyContent: 'space-between',
								alignItems: 'center',
								pt: 8,
							}}
						>
							{t('common:description')}: {activity.description}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:including')}:{' '}
							{activity.including?.map((item, i) => (
								<div key={i}>{item}, </div>
							))}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:not_including')}:{' '}
							{activity.not_including?.map((item, i) => (
								<div key={i}>{item}, </div>
							))}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:price')}: {activity.price?.currency} {activity.price?.value}
						</Box>
						<Box sx={{ mt: 1, verticalAlign: 'center' }}>
							{t('common:persons')}: {activity.price?.persons}
						</Box>
					</AccordionDetails>
				</>
			}
		</Accordion>
	);
}

export default MobileItem;
