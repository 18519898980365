import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import { MenuItem } from 'types/global';

type Props = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function Activities({ selected, onSelect }: Props) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/activities"
			selected={selected === MenuItem.Activity}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Activity)}
		>
			<ListItemIcon>
				<LocalFireDepartmentIcon />
			</ListItemIcon>
			<ListItemText primary={t('common:activity_plural')} />
		</ListItem>
	);
}
