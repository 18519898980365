import { Box, IconButton, TextField } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { Dispatch, SetStateAction } from 'react';
type ArrayItem<T> = T extends (infer U)[] ? U : never;

type Props<T extends object> = {
	item: T | null;
	setItem: Dispatch<SetStateAction<T | null>>;
	label: string;
	identifier: Extract<keyof T, string>;
};

const InputListManager = <T extends object>({ item, setItem, label, identifier }: Props<T>) => {
	const handleElementChange = (index: number, value: string) => {
		setItem((prevEditItem) => {
			if (!prevEditItem) {
				return null;
			}

			const currentIdentifier = prevEditItem[identifier];

			if (Array.isArray(currentIdentifier)) {
				const updatedIdentifier = [...currentIdentifier];
				updatedIdentifier[index] = value;

				return {
					...prevEditItem,
					[identifier]: updatedIdentifier as ArrayItem<typeof currentIdentifier>[],
				};
			}

			return prevEditItem;
		});
	};

	const addElementField = () => {
		setItem((prevEditItem) => {
			if (!prevEditItem) {
				return null;
			}

			const currentIdentifier = prevEditItem[identifier];

			if (Array.isArray(currentIdentifier)) {
				const updatedIdentifier = [...currentIdentifier, ''];

				return {
					...prevEditItem,
					[identifier]: updatedIdentifier as ArrayItem<typeof currentIdentifier>[],
				};
			}

			return prevEditItem;
		});
	};

	const removeElementField = (index: number) => {
		setItem((prevEditItem) => {
			if (!prevEditItem) {
				return null;
			}

			const currentIdentifier = prevEditItem[identifier];

			if (Array.isArray(currentIdentifier)) {
				const updatedIdentifier = [...currentIdentifier];
				updatedIdentifier.splice(index, 1);

				return {
					...prevEditItem,
					[identifier]: updatedIdentifier as ArrayItem<typeof currentIdentifier>[],
				};
			}

			return prevEditItem;
		});
	};
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<IconButton color="primary" onClick={addElementField} sx={{ mb: 1, ml: 1 }}>
					<AddIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					borderWidth: 2,
					borderRadius: 4,
					minHeight: 50,
					maxHeight: 400,
					width: '100%',
					mb: 2,
					py: 2,
					px: 2,
					backgroundColor: '#f0f0f0',
					borderColor: '#d3d3d3',
					borderStyle: 'solid',
					overflowY: 'auto',
				}}
			>
				{item &&
					item[identifier] &&
					Array.isArray(item[identifier]) &&
					(item[identifier] as string[]).map((element: string, index: number) => (
						<Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
							<TextField
								label={`${label} ${index + 1}`}
								fullWidth
								variant="outlined"
								value={element}
								sx={{ mb: 1, backgroundColor: 'white' }}
								onChange={(e) => handleElementChange(index, e.target.value)}
							/>
							{item[identifier] && (item[identifier] as string[]).length > 1 ? (
								<IconButton color="error" onClick={() => removeElementField(index)}>
									<DeleteIcon />
								</IconButton>
							) : (
								<IconButton color="error" onClick={() => removeElementField(index)} disabled={true}>
									<DeleteIcon />
								</IconButton>
							)}
						</Box>
					))}
			</Box>
		</>
	);
};

export default InputListManager;
